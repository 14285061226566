import { SwapStatus } from '../../hooks/useSwapById';
import { Chain } from '../BTCVConverter/consts';

export const isTransactionCompleted = (
  swapStatus: SwapStatus,
  amountRecieved: string,
  amountRequested: string
): boolean => {
  if (
    swapStatus === SwapStatus.FINISHED ||
    swapStatus === SwapStatus.FUNDS_SENT ||
    swapStatus === SwapStatus.SECURING_FUNDS
  ) {
    return true;
  }
  if (
    swapStatus === SwapStatus.RETURNING_FUNDS ||
    swapStatus === SwapStatus.FUNDS_RETURNED
  ) {
    const received = parseFloat(amountRecieved);
    const requested = parseFloat(amountRequested);

    return received > requested;
  }
  return false;
};

export const calculateProgress = (
  amountRecieved: number,
  total: number
): number => {
  const cal = amountRecieved * 100;

  return cal / total;
};

export const getChainSymbol = (chain: string): string => {
  if (chain === Chain.BSC) {
    return 'wBTCV';
  }
  return 'BTCV';
};

export const getRefreshInterval = (chain: string): number => {
  const oneMinute = 60 * 1000;
  const halfMinute = 30 * 1000;

  if (chain === Chain.BSC) {
    return halfMinute;
  }
  return oneMinute;
};
