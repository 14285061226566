export enum ModalType {
  INFO = 'info',
  ERROR = 'error',
}

export interface ModalFuncProps {
  open: () => void;
  openErrorMessage: (message: string) => void;
  close: () => void;
}

export interface ModalRef
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  open: () => void;
  openErrorMessage: (message: string) => void;
  close: () => void;
}
export type ModalProps = {
  children?: React.ReactNode;
  headline?: React.ReactNode;
  defaultOpened?: boolean;
  type: ModalType;
  onClose?: () => void;
};
