import styled from 'styled-components';
import { Dialog } from '@reach/dialog';
import Button from '../../shared/button';

export const Modal = styled(Dialog)`
  width: 606px;
  height: 400px;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  @media ${({ theme }) => theme.device.md} {
    width: 80%;
    padding: 50px 0;
  }
`;

export const Title = styled.h2`
  margin: 97px auto 0 auto;
  font-size: ${({ theme }) => theme.fontSize.desktopBig};
  @media ${({ theme }) => theme.device.md} {
    margin: 0px auto 0 auto;
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fontSize.menuTitle};
  }
`;

export const Paragraph = styled.p`
  margin: 10px auto 0 auto;
  text-align: center;
  white-space: break-spaces;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSize.mobileTitleBig};

  @media ${({ theme }) => theme.device.md} {
    font-size: ${({ theme }) => theme.fontSize.regular};
  }
`;

export const BackButton = styled(Button)`
  margin: 40px auto 0 auto;
  min-width: 200px;
`;
