import styled from 'styled-components';
import { ReactComponent as Checked } from '../../../assets/images/checkbox-ok.svg';

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const CheckedIcon = styled(Checked)`
  position: absolute;
  top: 5px;
  left: 5px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${(props) =>
    props.checked
      ? props.theme.colors.secondary
      : props.theme.colors.lightGray};
  transition: all 150ms;
  position: relative;

  ${({ theme }) => `border-radius: ${theme.borderRadius}`};
`;

export const Label = styled.span`
  margin-left: 10px;
`;

export const LabelWrapper = styled.label`
  margin-top: 40px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    display: flex;
    flex-direction: row;
  }
`;
