import styled from 'styled-components';

export const Line = styled.div`
  height: 1px;
  border: none;
  margin-bottom: 20px;

  ${({ theme }) => `background-color: ${theme.colors.secondary}`};
`;

export const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;
