/* eslint-disable func-names */
import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';
import { isBTCVAddressValid } from './index';
import { SwapDirection } from '../components/BTCVConverter/interfaces';
import { Chain } from '../components/BTCVConverter/consts';

const isBSCAddress = /^0x[a-fA-F0-9]{40}$/;

yup.addMethod(
  yup.string,
  'isWalletAddressValid',
  function (errorMessage, direction: SwapDirection) {
    return this.test(
      `test-wallet-address`,
      errorMessage,
      function (value: string | undefined) {
        const { path, createError } = this;

        if (direction.from === Chain.BSC) {
          return (
            isBTCVAddressValid(value) ||
            createError({ path, message: errorMessage })
          );
        }
        return (
          (value && isBSCAddress.test(value)) ||
          createError({ path, message: errorMessage })
        );
      }
    );
  }
);

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    isWalletAddressValid(
      error: string,
      direction: SwapDirection
    ): StringSchema<TType, TContext>;
  }
}

export default yup;
