import styled from 'styled-components';

export const StyledInput = styled.input.attrs((props: any) => ({
  'data-test-id': props.testid,
}))<{
  errors?: string;
  dir?: string;
  testid?: string;
}>`
  width: 570px;
  padding: 21px 11px 21px 11px;
  font-weight: bold;
  border-radius: 8px;
  position: relative;

  ::placeholder {
    ${({ theme }) => `color: ${theme.colors.darkGray};
    font-size: ${theme.fontSize.regular}`};
    line-height: 24px;
    font-weight: 400;
  }

  ${({ theme }) => `background-color: ${theme.colors.lightGray};
  font-size: ${theme.fontSize.title};
  border: 1px solid ${theme.colors.white}`};

  ${(props) =>
    props?.errors?.length
      ? `border: 1px solid ${props.theme.colors.red};`
      : ''};

  @media ${({ theme }) => `${theme.device.xs}`} {
    width: 275px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${({ dir }) =>
      dir === 'rtl'
        ? 'padding: 21px 21px 21px 11px;'
        : 'padding: 21px 45px 21px 11px;'};
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 570px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    width: 275px;
  }
`;

export const StyledLabel = styled.label<{
  active: boolean;
  errors: string | undefined;
  direction: string;
}>`
  display: none;
  ${({ theme }) => `color: ${theme.colors.darkGray};
  font-size: ${theme.fontSize.regular}`};

  ${({ active }) =>
    active &&
    `
    display: block;
    position: absolute;
    line-height: 24px;
    top: 3px;
  `}

  ${({ direction }) => (direction === 'rtl' ? 'right: 15px' : 'left: 15px')};

  ${(props) =>
    props?.errors?.length ? `color: ${props.theme.colors.red}` : ''};
`;

export const InputLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ExtraElementContainer = styled.div<{ direction: string }>`
  position: absolute;
  top: 22px;
  ${({ direction }) => (direction === 'rtl' ? 'left: 20px' : 'right: 20px')};
  font-weight: bold;
`;

export const ErrorContainer = styled.div<{ direction: string }>`
  position: absolute;
  ${({ direction }) => (direction === 'rtl' ? 'right: 0px' : 'left: 0px')};

  ${({ theme }) => `color: ${theme.colors.red}`};
`;
