/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import NumberFormat from 'react-number-format';
import { round } from 'reliable-round';
import {
  FormValues,
  APISwapConditions,
  SwapDirection,
  ExtendedFormValues,
} from './interfaces';
import Input from '../shared/Input';
import { SwitcherRow, ConversionRatio, FeeRow } from './styled';
import { ReactComponent as Switch } from '../../assets/images/switch.svg';
import { ExtraElement } from '../shared/Input/interfaces';
import { btcvToBscDirection, bscToBtcvDirection, InputName } from './consts';
import useSwapConditions from '../../hooks/useSwapConditions';
import { BoldText } from '../shared/styled/styled';
import useLanguageDir from '../../hooks/useLanguageDir';

const DEFAULT_DECIMAL_SCALE = 8;
const DEFAULT_NUMBER_INTERVAL = 0.0001;
const SEND_INIT_VALUE = 0;
const MAX_INPUT_LENGTH = 30;

interface ConversionSectionProps {
  formik: FormikProps<FormValues>;
  onSwapConditionsSuccess: (
    exchangeFeeValue: string,
    networkFeeValue: string,
    maxExchangeAm: number,
    swapDirection: SwapDirection
  ) => void;
  state: ExtendedFormValues;
}

const calculateInputValueMinusFees = (
  sendValue: number,
  exchangeFee: string,
  networkFee: string
): number => {
  const val =
    sendValue - sendValue * parseFloat(exchangeFee) - parseFloat(networkFee);

  return round(val, DEFAULT_DECIMAL_SCALE);
};

const ConversionSection: React.FC<ConversionSectionProps> = ({
  formik,
  onSwapConditionsSuccess,
  state,
}: ConversionSectionProps) => {
  const { t } = useTranslation();
  const dir = useLanguageDir();
  const [sendValue, setSendValue] = useState(SEND_INIT_VALUE);
  const [swapDirection, setSwapDirection] =
    useState<SwapDirection>(btcvToBscDirection);

  const fillConversionFields = (swap: APISwapConditions) => {
    formik.setFieldValue(InputName.SEND, sendValue);
    formik.setFieldValue(
      InputName.GET,
      calculateInputValueMinusFees(
        sendValue,
        swap.exchange_fee,
        swap.network_fee
      )
    );
  };

  const { data } = useSwapConditions(
    swapDirection.from,
    swapDirection.to,
    (swap: APISwapConditions) => {
      if (state !== undefined) {
        if (sendValue === SEND_INIT_VALUE) {
          formik.setFieldValue(InputName.SEND, parseInt(state?.send, 10));
          setSendValue(parseInt(state?.send, 10));
          formik.setFieldValue(
            InputName.GET,
            calculateInputValueMinusFees(
              parseInt(state?.send, 10),
              swap.exchange_fee,
              swap.network_fee
            )
          );
        } else {
          fillConversionFields(swap);
        }
      } else if (sendValue !== SEND_INIT_VALUE) {
        fillConversionFields(swap);
      }

      onSwapConditionsSuccess(
        swap.exchange_fee,
        swap.network_fee,
        swap.max_exchange_amount,
        swapDirection
      );
    }
  );

  const handleCalculateInputValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    swapData: APISwapConditions | undefined
  ) => {
    const inputValue = parseFloat(e.target.value);

    if (e.target.value.length > MAX_INPUT_LENGTH) {
      return false;
    }

    formik.setFieldValue(InputName.SEND, e.target.value);
    setSendValue(inputValue);
    if (!_.isNaN(inputValue)) {
      if (swapData) {
        const calculatedGetInputValue =
          inputValue -
          inputValue * parseFloat(swapData.exchange_fee) -
          parseFloat(swapData.network_fee);

        formik.setFieldValue(
          InputName.GET,
          round(calculatedGetInputValue, DEFAULT_DECIMAL_SCALE)
        );
      }
    } else {
      setSendValue(SEND_INIT_VALUE);
      formik.setFieldValue(InputName.GET, '');
    }
  };

  const handleConvertionSwitch = (): void => {
    if (_.isEqual(swapDirection, btcvToBscDirection)) {
      setSwapDirection(bscToBtcvDirection);
    } else {
      setSwapDirection(btcvToBscDirection);
    }
  };

  return (
    <>
      <Input
        id={InputName.SEND}
        testid="send"
        type="number"
        step={DEFAULT_NUMBER_INTERVAL}
        min="0"
        name={InputName.SEND}
        label={t('input.send')}
        dir={dir}
        value={formik.values.send}
        onChange={(e) => handleCalculateInputValue(e, data)}
        onBlur={formik.handleBlur}
        extras={[
          [
            ExtraElement.Text,
            _.isEqual(swapDirection, btcvToBscDirection)
              ? t('btcv')
              : t('wbtcv'),
          ],
        ]}
        errors={
          formik.touched.send && formik.errors.send ? formik.errors.send : ''
        }
      />
      <SwitcherRow>
        <ConversionRatio>1 BTCV = 1 wBTCV</ConversionRatio>
        <Switch onClick={handleConvertionSwitch} data-testid="switcher" />
      </SwitcherRow>
      <Input
        id={InputName.GET}
        disabled
        testid="get"
        type="number"
        name={InputName.GET}
        label={t('input.get')}
        dir={dir}
        value={formik.values.get}
        extras={[
          [
            ExtraElement.Text,
            _.isEqual(swapDirection, btcvToBscDirection)
              ? t('wbtcv')
              : t('btcv'),
          ],
        ]}
        errors={formik.errors.get ? formik.errors.get : ''}
      />
      <FeeRow direction={dir}>
        {data && (
          <>
            <span>
              {t('exchangeFee')}{' '}
              <BoldText>
                <NumberFormat
                  data-test-id="exchangeFee"
                  value={sendValue * parseFloat(data.exchange_fee)}
                  displayType="text"
                  decimalScale={DEFAULT_DECIMAL_SCALE}
                />{' '}
                BTCV
              </BoldText>
            </span>
            <span>
              {t('networkFee')}{' '}
              <BoldText>
                <NumberFormat
                  data-test-id="networkFee"
                  value={data.network_fee}
                  displayType="text"
                  decimalScale={DEFAULT_DECIMAL_SCALE}
                />{' '}
                BTCV
              </BoldText>
            </span>
          </>
        )}
      </FeeRow>
    </>
  );
};

export default ConversionSection;
