import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Wrapper,
  Content,
  TransactionIdRow,
  TransactionIdDesc,
  CopyRow,
  QRCodeWrapper,
  TimerRow,
  TimerDesc,
  CopyIcon,
  WalletRow,
  TransactionRow,
  TransactionId,
  Overlay,
} from './styled';
import { Line } from '../shared/styled/styled';
import SummaryField from '../shared/SummaryField';
import useSwapById, { SwapIdResponse } from '../../hooks/useSwapById';
import Layout from '../shared/Layout';
import CountDownTimer from './CountDownTimer';
import CustomQRCode from './CustomQRCode';
import { CompleteTransactionParam } from './interfaces';
import ProgressBar from './ProgressBar';
import { Routes, MessageType } from '../shared/consts/index';
import {
  getChainSymbol,
  getRefreshInterval,
  isTransactionCompleted,
  calculateProgress,
} from './helpers';
import { SwapValidateResponse } from '../TransactionSummary/interfaces';
import Modal from '../shared/modal';
import { ModalType, ModalFuncProps } from '../shared/modal/interfaces';
import { SwapErrorResponse } from '../shared/interfaces/response.interface';

const oneMinute = 60 * 1000;

const CompleteTransaction = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const errorModal = useRef<ModalFuncProps>(null);
  const [isTimeout, setIsTimeout] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(oneMinute);
  const [enableRef, setEnableRef] = useState(false);
  const [progress, setProgress] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const { state } = useLocation<SwapValidateResponse>();
  const { id } = useParams<CompleteTransactionParam>();
  const chainSymbol = getChainSymbol(state && state.chain_in);
  const { data, refetch } = useSwapById(
    id,
    refreshInterval,
    enableRef,
    (response: SwapIdResponse) => {
      setEnableRef(true);

      if (parseFloat(response.amount_received) > 0) {
        const transactionProgress = calculateProgress(
          parseFloat(response.amount_received),
          parseFloat(response.amount_requested)
        );

        if (transactionProgress > 99 && transactionProgress < 100) {
          setProgress(99);
        } else if (transactionProgress === 100) {
          setProgress(100);
        } else {
          setProgress(transactionProgress);
        }
      }

      if (
        isTransactionCompleted(
          response.swap_status,
          response.amount_received,
          response.amount_requested
        )
      ) {
        history.push({
          pathname: `${Routes.CompleteTransaction}/${id}/success`,
          state: {
            message: 'transactionComplete',
            messageType: MessageType.SUCCESS,
          },
        });
      }
    },
    (errors: SwapErrorResponse) => {
      setModalOpen(true);
      errorModal.current?.openErrorMessage(errors.msg);
    }
  );

  const handleCopy = (text: string | undefined) => {
    if (text) {
      navigator.clipboard.writeText(text);
    }
  };

  const handleTimeout = () => {
    setIsTimeout(true);
  };

  useEffect(() => {
    if (state) {
      const interval = getRefreshInterval(state.chain_in);

      setRefreshInterval(interval);
    }
  }, [state]);

  useEffect(() => {
    refetch();
  }, [refetch, enableRef]);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Layout>
      <Wrapper inProgress={progress > 0}>
        <Overlay modalOpen={modalOpen} />
        <Content>
          <TransactionIdRow>
            {t('transactionId')}{' '}
            <TransactionId>
              {data && data.swap_id}
              <CopyIcon onClick={() => handleCopy(data && data.swap_id)} />
            </TransactionId>
          </TransactionIdRow>
          <TransactionIdDesc>{t('transactionDescription')}</TransactionIdDesc>
          <Line />
          <TransactionRow inProgress={progress > 0}>
            <SummaryField
              id="txAmount"
              label={t('transactionAmount')}
              value={`${data && data.amount_requested} ${chainSymbol}`}
            />
            {data && progress > 0 && (
              <SummaryField
                id="status"
                label={t('amountStatus')}
                value={`${data.amount_received} ${chainSymbol} / ${data.amount_requested} ${chainSymbol}`}
              />
            )}
          </TransactionRow>
          {data && progress > 0 && (
            <ProgressBar value={progress} max={100} width={570} />
          )}
          <WalletRow>
            <SummaryField
              id="walletAddress"
              label={t('input.walletAddress.label')}
              value={data ? data.deposit_address : ''}
              extra={
                <CopyRow
                  data-test-id
                  onClick={() => handleCopy(data && data.deposit_address)}
                >
                  <CopyIcon /> {t('copyAddress')}
                </CopyRow>
              }
            />
          </WalletRow>
          <QRCodeWrapper>
            {data && <CustomQRCode value={data.deposit_address} />}
          </QRCodeWrapper>
          <Line />
          <TimerRow>
            {data && (
              <CountDownTimer
                timestamp={data.timestamp}
                timeout={data.timeout_timestamp}
                onTimeout={handleTimeout}
              />
            )}
            <TimerDesc>
              {isTimeout
                ? t('transactionTimerDescriptionTimeout')
                : t('transactionTimerDescription')}
            </TimerDesc>
          </TimerRow>
        </Content>
        <Modal
          ref={errorModal}
          type={ModalType.ERROR}
          onClose={handleModalClose}
        />
      </Wrapper>
    </Layout>
  );
};

export default CompleteTransaction;
