import React from 'react';
import { useTranslation } from 'react-i18next';
import usePriceBTCV from '../../hooks/usePriceBTCV';
import useIsMobile from '../../hooks/useIsMobile';
import theme from '../../styles/theme';
import {
  TopbarStatsContainer,
  InnerWrapper,
  TextWrapper,
  Statistic,
  Title,
  Text,
} from './styled';
import { EXPLORER_LINK } from '../shared/consts';
import LanguageSelect from '../shared/LanguageSelect';

const TopbarStats = (): JSX.Element => {
  const price = usePriceBTCV();
  const isMobile = useIsMobile(theme.deviceSize.mobile);
  const { t } = useTranslation();

  return (
    <TopbarStatsContainer id="topbar">
      <InnerWrapper>
        <TextWrapper>
          <Statistic>
            <Title>BITCOIN VAULT: </Title>
            <Text>{price} USD</Text>
          </Statistic>
          {!isMobile && (
            <Statistic>
              <a href={EXPLORER_LINK} target="_blank" rel="noopener noreferrer">
                <Text>{t('explorer')}</Text>
              </a>
            </Statistic>
          )}
        </TextWrapper>
        <Statistic>
          <LanguageSelect listOnBottom topBar />
        </Statistic>
      </InnerWrapper>
    </TopbarStatsContainer>
  );
};

export default TopbarStats;
