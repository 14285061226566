import styled, { css } from 'styled-components';

export const Wrapper = styled.button<{ topBar: boolean }>`
  color: ${({ topBar, theme }) =>
    topBar ? theme.colors.white : theme.colors.black};
  border: 2px solid
    ${({ topBar, theme }) => (topBar ? theme.colors.white : theme.colors.black)};
  border-radius: 24px;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  outline: 0;
`;

export const Text = styled.span`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: uppercase;
`;

export const SelectedLanguageLocale = styled(Text)`
  @media ${({ theme }) => theme.device.lg} {
    display: none;
  }
`;

export const SelectedLanguageName = styled(Text)`
  display: none;

  @media ${({ theme }) => theme.device.lg} {
    display: inline-block;
  }
`;

export const CurrentLanguage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;

  svg,
  img {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    border-radius: 50%;
  }
`;

type ArrowType = {
  isOpen: boolean;
  topBar: boolean;
};

export const ArrowWrapper = styled.div<ArrowType>`
  color: ${({ topBar, theme }) =>
    topBar ? theme.colors.white : theme.colors.black};
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg) translate(5px);
    `}

  svg {
    width: 10px;
    height: 10px;
  }
`;

export const LanguageListWrapper = styled.ul<{
  isOpen: boolean;
  listOnBottom: boolean;
  topBar: boolean;
}>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  list-style: none;
  position: absolute;
  right: 0;
  border: 2px solid
    ${({ topBar, theme }) => (topBar ? theme.colors.white : theme.colors.black)};
  border-radius: 15px;
  z-index: 1;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.white};
  min-width: 160px;
  width: 100%;
  padding: 5px 0;
  top: 100%;
  bottom: unset;

  @media ${({ theme }) => theme.device.lg} {
    top: ${({ listOnBottom }) => (listOnBottom ? '100%' : 'unset')};
    bottom: ${({ listOnBottom }) => (listOnBottom ? 'unset' : '100%')};
  }
`;

export const ListItem = styled.li`
  cursor: pointer;
  padding: 5px 14px;
  transition: ${({ theme }) => theme.ui.transition('opacity')};
  text-align: left;

  &:hover {
    opacity: ${({ theme }) => theme.ui.opacity()};
  }

  svg,
  img {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    border-radius: 50%;
  }
`;

export const StyledLink = styled.div`
  color: ${({ theme }) => theme.colors.black};
  display: inline-flex;
  align-items: center;
`;
