import React from 'react';
import { useTranslation } from 'react-i18next';
import { FOOTER_DATA, footerSections } from '../shared/consts';
import {
  ColumnWrapper,
  ColumnTitle,
  StyledAnchorLink,
  StyledLink,
} from './styled';

const FooterLinks = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      {FOOTER_DATA.map(({ title, links }) => (
        <ColumnWrapper key={title} gridArea={title}>
          <ColumnTitle>{title}</ColumnTitle>
          <ul>
            {links.map(({ name, link }) => {
              if (title === footerSections.education) {
                return (
                  <li key={name}>
                    <StyledAnchorLink href={link}>{t(name)}</StyledAnchorLink>
                  </li>
                );
              }

              return (
                <li key={name}>
                  <StyledLink href={link}>{t(name)}</StyledLink>
                </li>
              );
            })}
          </ul>
        </ColumnWrapper>
      ))}
    </>
  );
};

export default FooterLinks;
