import styled from 'styled-components';
import Button from '../button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  width: 450px;
  height: 390px;
  background-color: white;
  margin-top: 100px;
  margin-bottom: 550px;

  ${({ theme }) => `border-radius: ${theme.borderRadius}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin-bottom: 162px;
    margin-top: 50px;
    width: 315px;
    height: 384px;
    padding: 30px;
  }
`;

export const IconWrapper = styled.div`
  margin-bottom: 20px;
`;

export const Line = styled.hr`
  margin-bottom: 20px;
  width: 100%;
  border: none;

  ${({ theme }) => `border-bottom: 1px solid ${theme.colors.secondary}`};
`;

export const Message = styled.div`
  margin-bottom: 20px;
  font-weight: 300;
  text-align: center;

  ${({ theme }) => `font-size: ${theme.fontSize.title}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.regular}`};
  }
`;

export const Headline = styled.div`
  font-weight: bold;
  margin-bottom: 30px;

  ${({ theme }) => `font-size: ${theme.fontSize.mobileTitleBig}`};
`;

export const HomeButton = styled(Button)`
  margin: 0px;
  padding: 14px 43px;
  width: 200px;
  letter-spacing: normal;

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding: 14px 43px;
  }
`;
