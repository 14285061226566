import styled, { css } from 'styled-components';
import { ReactComponent as TickSvg } from '../../assets/images/tick.svg';
import { ReactComponent as LogoWithTextSvg } from '../../assets/images/logo-with-text.svg';
import { ReactComponent as CloseSvg } from '../../assets/images/close.svg';
import { ReactComponent as CloseMobileSvg } from '../../assets/images/close-mobile.svg';

export const Wrapper = styled.div<{ isMenuOpen?: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: ${({ isMenuOpen }) => (isMenuOpen ? 'block' : 'none')};
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
  display: block;

  @media ${({ theme }) => theme.device.xs} {
    display: none;
  }
`;

export const Sidebar = styled.nav`
  @media ${({ theme }) => theme.device.lg} {
    position: fixed;
    width: 420px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primaryMedium};
    top: ${({ theme }) => theme.dimensions.topbarHeight}px;
    bottom: 0;
    right: 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }

  @media ${({ theme }) => theme.device.xs} {
    background-color: ${({ theme }) => theme.colors.primaryMedium};
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
  }
`;

export const MobileLogo = styled(LogoWithTextSvg)`
  display: none;
`;

export const CloseButton = styled.button`
  background: transparent;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 1;
  outline: 0;

  @media ${({ theme }) => theme.device.xs} {
    right: 20px;
  }
`;

export const CloseDesktop = styled(CloseSvg)`
  display: block;

  @media ${({ theme }) => theme.device.xs} {
    display: none;
  }
`;

export const CloseMobile = styled(CloseMobileSvg)`
  @media ${({ theme }) => theme.device.lg} {
    display: none;
  }
`;

export const InnerWrapper = styled.ul`
  padding: 140px 100px 120px 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;

  a {
    transition: ${({ theme }) => theme.ui.transition('opacity')};

    &:hover {
      opacity: ${({ theme }) => theme.ui.opacity()};
    }
  }

  @media ${({ theme }) => theme.device.xs} {
    padding: 30px 30px 50px;
  }
`;

export const sectionTitleStyles = css<{ isActive?: boolean; index?: number }>`
  font-size: ${({ theme }) => theme.fontSize.menuTitle};
  /* TODO - add functionality to determine if isActive  */
  font-weight: ${({ theme, isActive, index }) =>
    theme.fontWeight[isActive || index === 0 ? 'bold' : 'light']};
  color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  transition: ${({ theme }) => theme.ui.transition('opacity')};

  &:hover {
    opacity: ${({ theme }) => theme.ui.opacity()};
  }
`;

export const SectionTitle = styled.h2<{ isActive?: boolean; index?: number }>`
  ${sectionTitleStyles};
`;

export const List = styled.ul`
  list-style-type: none;
  margin: 10px 0;

  @media ${({ theme }) => theme.device.xs} {
    margin: 0;
  }
`;

export const ListItem = styled.li`
  margin-top: 10px;
`;

export const MenuItem = styled.li`
  margin-bottom: 20px;

  @media ${({ theme }) => theme.device.xs} {
    margin-bottom: 10px;
  }
`;

export const MenuLink = styled.a`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.device.xs} {
    font-size: ${({ theme }) => theme.fontSize.regular};
  }
`;

export const MenuItemWithList = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ToggleList = styled.button`
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  outline: 0;
  background: none;
  width: 24px;
  height: 24px;
  margin-left: 10px;
`;

export const Tick = styled(TickSvg)<{ open: boolean }>`
  transform: ${({ open }) => `rotate(${open ? '0' : '180deg'})`};
`;

export const SocialMediaWrapper = styled.aside`
  display: none;

  @media ${({ theme }) => theme.device.xs} {
    display: grid;
    grid-template-columns: repeat(8, 25px);
    grid-gap: 10px;
    justify-items: center;
    margin: 100px auto 0;
    align-self: center;
  }
`;

export const StyledLink = styled.a`
  ${sectionTitleStyles};

  @media ${({ theme }) => theme.device.xs} {
    display: none;
  }
`;
