import { CSSProperties } from 'react';
import styled from 'styled-components';
import { fullWidthStyles } from '../TopbarStats/styled';
import { footerSections } from '../shared/consts/index';

const { home, development, education, joinUsOn, needHelp } = footerSections;

export const Wrapper = styled.footer`
  ${fullWidthStyles};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
`;

export const InnerWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  display: grid;
  grid-template-areas: '${home} ${development} ${education} ${joinUsOn} ${needHelp}';
  grid-gap: 80px;
  padding: 60px 20px 80px;
  max-width: ${({ theme }) => theme.dimensions.innerWrapper}px;

  @media ${({ theme }) => theme.device.md} {
    padding: 30px 5%;
    grid-template-areas: '${home} ${needHelp}' '${education} ${joinUsOn}' '${development} .';
  }

  @media ${({ theme }) => theme.device.xs} {
    padding: 30px 20px;
    white-space: break-spaces;
    grid-gap: 40px;
    grid-template-areas: '${needHelp}' '${home}' '${development}' '${education}' '${joinUsOn}';
  }
`;

export const ColumnWrapper = styled.div<{
  gridArea: CSSProperties['gridArea'];
}>`
  display: flex;
  flex-direction: column;
  grid-area: ${({ gridArea }) => gridArea};
  align-items: flex-start;
  text-align: left;

  &:last-of-type {
    a {
      line-height: normal;
    }
  }
  @media ${({ theme }) => theme.device.xs} {
    align-items: center;
    text-align: center;
  }
`;

export const ColumnTitle = styled.h3`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSize.regular};
  text-transform: uppercase;
  margin-bottom: 16px;

  @media ${({ theme }) => theme.device.xs} {
    margin-bottom: 10px;
  }
`;

export const StyledLink = styled.a`
  line-height: 1.53;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const StyledAnchorLink = styled.a`
  line-height: 1.53;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const SocialMediaWrapper = styled.aside`
  display: grid;
  grid-template-columns: repeat(2, 25px);
  grid-gap: 10px;
  margin-top: 5px;

  @media ${({ theme }) => theme.device.xs} {
    grid-template-columns: repeat(8, 25px);
  }
`;

export const LanguageWrapper = styled.div`
  margin: 16px 0;
`;

export const CookiesAndPrivacyWrapper = styled.div`
  margin-bottom: 16px;

  a {
    display: inline-flex;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.53;
`;
