import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../styles/theme';
import useWindowOffset from '../../hooks/useWindowOffset';
import usePriceBTCV from '../../hooks/usePriceBTCV';
import {
  NavigationContainer,
  StyledLink,
  BtcvLogo,
  CenterWrapper,
  TextWrapper,
  PriceBTCV,
  ExplorerLink,
  SocialMediaWrapper,
  ToggleMenu,
} from './stylex';
import Menu from '../Menu';
import SocialMediaList from '../SocialMediaList';
import { EXPLORER_LINK, Routes } from '../shared/consts';
import { ReactComponent as Hamburger } from '../../assets/images/hamburger.svg';
import LanguageSelect from '../shared/LanguageSelect';

interface StickyNavigationProps {
  isMenuOpen: boolean;
  hideNav: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
}

const OFFSET = 140;

const StickyNavigation = ({
  isMenuOpen,
  setIsMenuOpen,
  hideNav,
}: StickyNavigationProps): JSX.Element => {
  const offset = useWindowOffset();
  const price = usePriceBTCV();
  const show = offset >= OFFSET;
  const { t } = useTranslation();

  useEffect(() => {
    const topbarElem = document ? document.getElementById('topbar') : null;

    if (!topbarElem) {
      return;
    }
    if (isMenuOpen) {
      topbarElem.style.zIndex = '2';
    } else {
      topbarElem.style.zIndex = '3';
    }
  }, [isMenuOpen]);

  return (
    <NavigationContainer
      id="sticky-header"
      show={show}
      isMenuOpen={isMenuOpen}
      hideNav={hideNav}
    >
      <StyledLink to={Routes.Converter}>
        <BtcvLogo />
      </StyledLink>
      <CenterWrapper>
        <TextWrapper>
          <PriceBTCV>
            BTCV price: <span>{price} USD</span>
          </PriceBTCV>
          <ExplorerLink
            href={EXPLORER_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>{t('explorer')}</p>
          </ExplorerLink>
        </TextWrapper>
        <SocialMediaWrapper>
          <SocialMediaList iconColor={theme.colors.secondary} />
        </SocialMediaWrapper>
        <LanguageSelect listOnBottom topBar />
      </CenterWrapper>
      <ToggleMenu onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <Hamburger />
      </ToggleMenu>
      <Menu handleClose={() => setIsMenuOpen(false)} isMenuOpen={isMenuOpen} />
    </NavigationContainer>
  );
};

export default StickyNavigation;
