import React, { Suspense } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import BTCVConverter from './components/BTCVConverter';
import TransactionSummary from './components/TransactionSummary';
import CompleteTransaction from './components/CompleteTransaction';
import MessageWindow from './components/shared/MessageWindow';
import theme from './styles/theme';
import '@fontsource/ubuntu/300.css';
import '@fontsource/ubuntu/400.css';
import '@fontsource/ubuntu/700.css';
import { Routes } from './components/shared/consts';
import { Config } from './Config';

const loaderStyle = {
  backgroundImage:
    'linear-gradient(to bottom, #f70949 -101%,#1f011f 47%,#f70949 182%)',
  minHeight: '100vh',
};

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }

  body {
    margin: 0;
    padding: 0;
    background: ${theme.colors.white};
    font-family: Ubuntu, sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: ${theme.fontWeight.bold};
    line-height: normal;
  }

  h3, h4, h5, h6 {
    font-size: ${theme.fontSize.desktopTitle}
  }

  h1, h2 {
    font-size: ${theme.fontSize.desktopBig};
    @media ${theme.device.xs} {
      font-size: ${theme.fontSize.menuTitle};
    } 
  }

  ul {
    list-style-type: none;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  a {
    width: auto;
    text-decoration: none;
    transition: ${theme.ui.transition('opacity')};

    &:hover {
      opacity: ${theme.ui.opacity()};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  [data-reach-dialog-overlay] {
    z-index: 4;
    background: rgba(0,0,0,.33);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
  }

  [data-reach-dialog-content] {
    margin: 10vh auto;
    background: #fff;
  }

  .grecaptcha-badge { visibility: hidden; }
`;

const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <GoogleReCaptchaProvider reCaptchaKey={Config.recaptchaKey}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<div style={loaderStyle} />}>
            <Router>
              <Switch>
                <Route
                  exact
                  path={Routes.Converter}
                  component={BTCVConverter}
                />
                <Route
                  path={`${Routes.Summary}/:token`}
                  component={TransactionSummary}
                />
                <Route
                  exact
                  path={`${Routes.CompleteTransaction}/:id`}
                  component={CompleteTransaction}
                />
                <Route
                  exact
                  path={`${Routes.CompleteTransaction}/:id/success`}
                  component={MessageWindow}
                />
                <Route
                  exact
                  path={`${Routes.Error}`}
                  component={MessageWindow}
                />
                <Route
                  path="*"
                  component={() => {
                    window.location.href = Config.LandingPageUrl || '/';

                    return null;
                  }}
                />
              </Switch>
            </Router>
          </Suspense>
        </ThemeProvider>
      </GoogleReCaptchaProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
