import { useQuery, setLogger } from 'react-query';
import axios from 'axios';
import {
  SwapErrorResponse,
  SwapError,
} from '../components/shared/interfaces/response.interface';
import { Config } from '../Config';

setLogger({
  log: () => {},
  warn: () => {},
  error: () => {},
});
export interface SwapInitResponse {
  email_validation_timeout: string;
  session_token: string;
  result: string;
  error_code: number;
  msg: string;
}

export const getSwapInit = async (
  email: string,
  lang: string,
  chainIn: string,
  chainOut: string,
  destinationAddress: string,
  amount: string
) => {
  const { data } = await axios.post(`${Config.ApiUrl}/swap/init/`, {
    email,
    lang,
    chain_in: chainIn,
    chain_out: chainOut,
    destination_address: destinationAddress,
    amount,
  });

  return data;
};

export default function useSwapInit(
  email: string,
  lang: string,
  chainIn: string,
  chainOut: string,
  destinationAddress: string,
  amount: string,
  errorData: (data: SwapErrorResponse) => void
) {
  return useQuery(
    ['swapinit', chainIn, chainOut],
    () =>
      getSwapInit(email, lang, chainIn, chainOut, destinationAddress, amount),
    {
      onError: (error: SwapError) => {
        errorData(error.response.data);
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
}
