import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItem, MenuItem as MenuItemType } from '../shared/consts';
import { MenuLink, ListItem } from './styled';

interface MenuLinksProps {
  data: MenuItem[];
  handleClose: () => void;
}

const MenuLinks = ({ data, handleClose }: MenuLinksProps): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();

  const handleMenuElementClick = (url: string) => {
    if (location.pathname === url) {
      return handleClose();
    }
    return null;
  };

  return (
    <>
      {data.map(({ name: subLinkName, url: subLinkUrl }: MenuItemType) => (
        <ListItem
          key={subLinkName}
          onClick={() => handleMenuElementClick(subLinkUrl)}
        >
          <MenuLink href={subLinkUrl}>{t(subLinkName)}</MenuLink>
        </ListItem>
      ))}
    </>
  );
};

export default MenuLinks;
