/* eslint-disable import/no-cycle */
import styled from 'styled-components';
import { ModalType } from './interfaces';
import Button from '../button';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

export const ModalContainer = styled.div<{ type: ModalType }>`
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 90px 50px 50px 50px;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.19);
  height: ${({ type }) => (type === ModalType.INFO ? 'auto' : '390px')};
  max-height: 50vh;
  width: ${({ type }) => (type === ModalType.INFO ? '64%' : '450px')};
  max-width: 883px;
  overflow-y: ${({ type }) => (type === ModalType.INFO ? 'auto' : 'none')};
  z-index: 2;

  ${({ type }) =>
    type === ModalType.ERROR &&
    'display:flex; flex-direction: column; align-items: center; padding: 40px;'};

  ${({ theme }) => `background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius};
  font-size: ${theme.fontSize.title};`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding: 78px 30px 30px 30px;
    font-size: 13px;
    width: 315px;
    height: 384px;
  }
`;

export const ModalHeadline = styled.div`
  font-weight: bold;
  padding-bottom: 30px;

  ${({ theme }) => `font-size: ${theme.fontSize.desktopBig}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    font-size: 24px;
  }
`;

export const IconWrapper = styled.div`
  margin-bottom: 20px;
`;

export const Line = styled.hr`
  margin-bottom: 20px;
  width: 100%;
  border: none;

  ${({ theme }) => `border-bottom: 1px solid ${theme.colors.secondary}`};
`;

export const Headline = styled.div`
  font-weight: bold;
  margin-bottom: 30px;

  ${({ theme }) => `font-size: ${theme.fontSize.mobileTitleBig}`};
`;

export const Message = styled.div`
  margin-bottom: 20px;
  font-weight: 300;
  text-align: center;

  ${({ theme }) => `font-size: ${theme.fontSize.title}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.regular}`};
  }
`;

export const HomeButton = styled(Button)`
  margin: 0px;
  padding: 14px 43px;
  width: 200px;
  letter-spacing: normal;

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding: 14px 43px;
  }
`;
