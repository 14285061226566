import React from 'react';
import {
  FieldWrapper,
  FieldLabel,
  FieldValue,
  FieldDescription,
} from './styled';

interface SummaryFieldProps {
  label: string;
  value: string;
  description?: string;
  id?: string;
  extra?: React.ReactNode;
  dir?: string;
}

const SummaryField: React.FC<SummaryFieldProps> = ({
  label,
  value,
  description,
  id,
  extra,
  dir = 'ltr',
}: SummaryFieldProps) => {
  return (
    <>
      <FieldWrapper>
        <FieldLabel direction={dir}>{label}</FieldLabel>
        <FieldValue data-testid={id} direction={dir}>
          {value}
        </FieldValue>
        <FieldDescription direction={dir}>
          {description && description}
        </FieldDescription>
        {extra && extra}
      </FieldWrapper>
    </>
  );
};

SummaryField.defaultProps = {
  description: '',
  id: '',
  extra: <></>,
  dir: 'ltr',
};

export default SummaryField;
