import React from 'react';
import { useTranslation } from 'react-i18next';
import useLanguageDir from '../../../hooks/useLanguageDir';
import { Modal, Title, Paragraph, BackButton } from './styled';

interface Props {
  onGoBackClick: () => void;
}

const FeedbackSuccess = ({ onGoBackClick }: Props): JSX.Element => {
  const { t } = useTranslation();
  const dir = useLanguageDir();

  return (
    <Modal dir={dir}>
      <Title>{t('thankYou')}</Title>
      <Paragraph>{t(`theFormWasSuccessfullySubmitted`)}</Paragraph>
      <BackButton onClick={onGoBackClick}>{t('back')}</BackButton>
    </Modal>
  );
};

export default FeedbackSuccess;
