import styled from 'styled-components';
import { ReactComponent as Copy } from '../../assets/images/copy.svg';

export const Overlay = styled.div<{ modalOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: ${({ modalOpen }) => (modalOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 2;
  ${({ theme }) => `border-radius: ${theme.borderRadius}`};
`;

export const Wrapper = styled.div<{ inProgress: boolean }>`
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 653px;
  border: 1px solid black;
  margin-top: 17px;
  margin-bottom: 250px;
  position: relative;

  ${(props) => props.inProgress && `height: 729px`};

  ${({ theme }) => `background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius}`};

  @media ${({ theme }) => `${theme.device.lg}`} {
    max-width: 650px;
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    height: 773px;
    ${(props) => props.inProgress && `height: 836px`};
    max-width: 315px;
    margin-bottom: 150px;
    margin-top: 17px;
  }
`;

export const Content = styled.div`
  text-align: center;
  padding: 41px 40px 40px 40px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding: 30px 20px 90px 20px;
  }
`;

export const TransactionIdRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;
  font-weight: 300;

  @media ${({ theme }) => `${theme.device.xs}`} {
    flex-direction: column;
  }

  ${({ theme }) => `font-size: ${theme.fontSize.large}`};
`;

export const TransactionIdDesc = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-weight: 300;
  ${({ theme }) => `color: ${theme.colors.lightGrey};
  font-size: ${theme.fontSize.regular};`};
`;

export const CopyRow = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-weight: 300;
  cursor: pointer;
  ${({ theme }) => `color: ${theme.colors.secondary};
  font-size: ${theme.fontSize.regular}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }
`;

export const QRCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 25px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

export const TimerRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Timer = styled.div<{ isTimeout: boolean }>`
  font-weight: bold;
  margin-bottom: 5px;
  ${({ theme }) => `font-size: ${theme.fontSize.large}`};

  ${(props) => props.isTimeout && `color: ${props.theme.colors.red}`};
`;

export const TimerDesc = styled.div`
  font-weight: 300;
  padding: 0px 35px;
  text-align: center;
  line-height: 20px;
  margin-top: 5px;
  ${({ theme }) => `color: ${theme.colors.lightGrey};
  font-size: ${theme.fontSize.regular}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding: 0;
  }
`;

export const CopyIcon = styled(Copy)`
  padding: 5px;
  cursor: pointer;
`;

export const WalletRow = styled.div`
  @media ${({ theme }) => `${theme.device.xs}`} {
    word-break: break-word;
    text-align: center;
  }
`;

export const TransactionRow = styled.div<{ inProgress: boolean }>`
  margin-bottom: 10px;

  @media ${({ theme }) => `${theme.device.lg}`} {
    ${(props) =>
      props.inProgress && `display: flex; justify-content: space-between;`};
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin-bottom: 20px;
  }
`;

export const TimeoutMessage = styled.div<{ isTimeout: boolean }>`
  font-weight: bold;
  ${(props) => props.isTimeout && `color: ${props.theme.colors.red}`};
`;

export const TransactionId = styled.div`
  font-weight: bold;
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProgressWrapper = styled.div<{ width: number }>`
  margin-bottom: 30px;
  progress[value] {
    width: 570px;
    appearance: none;
    border: none;
    height: 6px;
    background: #f6f6f6;
    border-radius: 21px;

    ::-webkit-progress-value {
      height: 6px;
      border-radius: 21px;
      background: linear-gradient(90deg, #1f011f 0%, #f70949 100%);
    }

    ::-moz-progress-bar {
      height: 6px;
      border-radius: 21px;
      background: linear-gradient(90deg, #1f011f 0%, #f70949 100%);
    }

    ::-webkit-progress-bar {
      height: 6px;
      border-radius: 21px;
      background: #f6f6f6;
    }

    @media ${({ theme }) => `${theme.device.xs}`} {
      width: 275px;
    }
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin-bottom: 20px;
  }
`;

export const ProgressPercentage = styled.div`
  ${({ theme }) => `font-size: ${theme.fontSize.large}; 
  color: ${theme.colors.red}`};
  font-weight: bold;
  margin-bottom: 5px;
`;
