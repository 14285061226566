import React from 'react';
import QRCode from 'qrcode.react';
import theme from '../../styles/theme';
import { CustomQRCodeProps } from './interfaces';

const CustomQRCode = ({
  value,
  size = 180,
  renderAs = 'svg',
  level = 'H',
  key = 'level-H',
  fgColor = theme.colors.black,
  bgColor = theme.colors.white,
}: CustomQRCodeProps): JSX.Element => {
  return (
    <QRCode
      value={value}
      size={size}
      renderAs={renderAs}
      level={level}
      fgColor={fgColor}
      bgColor={bgColor}
      key={key}
    />
  );
};

CustomQRCode.defaultProps = {
  size: 180,
  renderAs: 'svg',
  level: 'H',
  key: 'level-H',
  fgColor: theme.colors.black,
  bgColor: theme.colors.white,
};

export default CustomQRCode;
