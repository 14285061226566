import { useState, useEffect } from 'react';

import useWindowSize from './useWindowSize';

const useIsMobile = (props: number) => {
  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (windowSize.width < props) {
      return setIsMobile(true);
    }
    return setIsMobile(false);
  }, [props, windowSize.width]);

  return isMobile;
};

export default useIsMobile;
