import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../hooks/useIsMobile';
import theme from '../../../styles/theme';
import TopbarStats from '../../TopbarStats';
import Navigation from '../../Navigation';
import StickyNavigation from '../../StickyNavigation';
import Footer from '../../Footer';
import { ReactComponent as Circle } from '../../../assets/images/circles.svg';
import { ReactComponent as BscIcon } from '../../../assets/images/icon_btcv.svg';
import { Config } from '../../../Config';

const Main = styled.main`
  flex: 1;
`;

const Wp = styled.div`
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  background-image: linear-gradient(
    to bottom,
    #f70949 -101%,
    #1f011f 47%,
    #f70949 182%
  );
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
`;

const SectionsWrapper = styled.div`
  z-index: 1;
`;

const BscAddress = styled.div`
  text-overflow: ellipsis;
  margin-left: 10px;
  padding-top: 2px;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  text-decoration-line: underline;
  line-height: 19px;
  color: ${theme.colors.yellow};

  a {
    color: ${theme.colors.yellow};
  }

  @media ${theme.device.xs} {
    width: 260px;
  }
`;

const AddressWrapper = styled.div`
  margin-top: 55px;
  text-align: center;
  display: flex;
  justify-content: center;

  @media ${theme.device.xs} {
    margin-top: 10px;
  }
`;

const SmartContract = styled.div`
  padding-top: 2px;
  color: ${theme.colors.yellow};
  margin-right: 10px;

  @media ${theme.device.xs} {
    text-align: center;
    margin-top: 10px;
  }
`;

const CirclesWrapper = styled.div`
  position: absolute;
  top: 40px;

  svg {
    transform: unset;
  }

  @media ${theme.device.xs} {
    top: -120px;

    svg {
      transform: scale(0.7);
    }
  }
`;

type LayoutProps = {
  children: JSX.Element;
};

const Layout = ({ children }: LayoutProps): JSX.Element => {
  const { t } = useTranslation();
  const [isMenuNormalOpen, setIsMenuNormalOpen] = useState(false);
  const [isMenuStickyOpen, setIsMenuStickyOpen] = useState(false);
  const isMobile = useIsMobile(theme.deviceSize.mobile);

  useEffect(() => {
    if ((isMenuNormalOpen || isMenuStickyOpen) && !isMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isMenuNormalOpen, isMenuStickyOpen, isMobile]);

  return (
    <Wrapper>
      <TopbarStats />
      <Navigation
        isMenuOpen={isMenuNormalOpen}
        setIsMenuOpen={setIsMenuNormalOpen}
        hideNav={isMenuStickyOpen}
      />
      <StickyNavigation
        isMenuOpen={isMenuStickyOpen}
        setIsMenuOpen={setIsMenuStickyOpen}
        hideNav={isMenuNormalOpen}
      />
      <Main>
        <Wp>
          <CirclesWrapper>
            <Circle />
          </CirclesWrapper>
          <SectionsWrapper>
            {isMobile && <SmartContract>{t('smartContract')}</SmartContract>}
            <AddressWrapper>
              {!isMobile && <SmartContract>{t('smartContract')}</SmartContract>}
              <div>
                <BscIcon />
              </div>
              <BscAddress>
                <a href={`${Config.bscscanUrl}/token/${Config.bscAddress}`}>
                  {Config.bscAddress}
                </a>
              </BscAddress>
            </AddressWrapper>
            {children}
          </SectionsWrapper>
        </Wp>
      </Main>
      <Footer />
    </Wrapper>
  );
};

export default Layout;
