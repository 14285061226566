import React from 'react';
import { FormikProps } from 'formik';
import { FormValues } from '../../BTCVConverter/interfaces';
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Label,
  LabelWrapper,
  CheckedIcon,
} from './styled';

interface CheckboxProps {
  checked: boolean;
  labelWrap?: boolean;
  label: React.ReactNode;
  formik: FormikProps<FormValues>;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  labelWrap = true,
  label,
  formik,
  ...props
}) => {
  const handleAgree = () => {
    formik.setFieldValue('checkbox', !formik.values.checkbox);
  };

  const content = (
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox
        checked={checked}
        onClick={handleAgree}
        data-testid="checkbox"
      >
        {checked && <CheckedIcon />}
      </StyledCheckbox>
    </CheckboxContainer>
  );

  return labelWrap ? (
    <LabelWrapper>
      {content}
      <Label>{label}</Label>
    </LabelWrapper>
  ) : (
    <>{content}</>
  );
  return content;
};

export default Checkbox;
