import { useEffect, useState } from 'react';

const useWindowOffset = (): number => {
  const [offset, setOffset] = useState(0);

  const scrollHandler = () => {
    setOffset(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    // eslint-disable-next-line no-restricted-globals
    return () => removeEventListener('scroll', scrollHandler);
  }, []);

  return offset;
};

export default useWindowOffset;
