export enum Chain {
  BTCV = 'BTCV',
  BSC = 'BSC',
}

export enum InputName {
  SEND = 'send',
  GET = 'get',
}

export const bscToBtcvDirection = {
  from: Chain.BSC,
  to: Chain.BTCV,
};

export const btcvToBscDirection = {
  from: Chain.BTCV,
  to: Chain.BSC,
};
