import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Wrapper as MenuWrapper,
  MobileLogo,
  CloseButton,
  SocialMediaWrapper as SocialMediaWrapperMenu,
  Sidebar,
} from '../Menu/styled';
import { Wrapper as LanguageSelectWrapper } from '../shared/LanguageSelect/styled';
import { ReactComponent as Logo } from '../../assets/images/logo-sticky-btcv.svg';

interface Nav {
  show: boolean;
  isMenuOpen: boolean;
  hideNav: boolean;
}

const OFFSET = 140;

export const StyledLink = styled(Link)``;

export const NavigationContainer = styled.header<Nav>`
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 5px 30px;
  position: fixed;
  top: 0;
  z-index: 10;
  opacity: ${({ hideNav, show }) => (hideNav || !show ? '0' : '1')};
  transform: ${({ isMenuOpen }) => !isMenuOpen && `translateY(-${OFFSET}px)`};
  transition: 0.5s transform ease-in;

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      ${Sidebar} {
        height: 100vh;
        top: 0;
      }
    `}

  ${({ show }) =>
    show &&
    css`
      transform: translateY(0);
    `}

  ${MenuWrapper} {
    top: 0;
    right: 0;
  }

  ${MobileLogo}, ${CloseButton} {
    top: 5px;
    position: absolute;
  }

  ${CloseButton} {
    right: 30px;
  }

  ${SocialMediaWrapperMenu} {
    margin-top: 75px;
  }

  ${StyledLink} {
    z-index: ${({ isMenuOpen }) => (isMenuOpen ? '0' : '3')};
  }

  @media ${({ theme }) => theme.device.xs} {
    ${({ isMenuOpen }) =>
      isMenuOpen &&
      css`
        ${Sidebar} {
          height: auto;
          top: 0;
        }
      `}

    ${LanguageSelectWrapper} {
      display: none;
    }
  }
`;

export const BtcvLogo = styled(Logo)`
  height: 50px;
  width: 46px;
  margin-top: 1px;
`;

export const ToggleMenu = styled.button`
  background-color: transparent;
  outline: 0;
  cursor: pointer;
  z-index: 1;
  justify-self: end;
`;

export const SocialMediaWrapper = styled.aside`
  display: grid;
  grid-template-columns: repeat(8, 25px);
  grid-gap: 10px;
  margin-right: 10%;

  @media ${({ theme }) => theme.device.md} {
    display: none;
  }
`;

export const PriceBTCV = styled.p`
  color: ${({ theme }) => theme.colors.white};
  span {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  @media ${({ theme }) => theme.device.xs} {
    font-size: ${({ theme }) => theme.fontSize.regular};
    span {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      font-size: ${({ theme }) => theme.fontSize.regular};
    }
  }

  @media ${({ theme }) => theme.device.lg} {
    margin-right: 20px;
  }
`;

export const ExplorerLink = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.device.xs} {
    display: none;
  }
`;

export const CenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 80%;
  max-width: ${({ theme }) => theme.dimensions.innerWrapper}px;

  @media ${({ theme }) => theme.device.xs} {
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
`;
