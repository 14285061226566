/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Telegram } from '../../assets/images/telegram.svg';
import { ReactComponent as Medium } from '../../assets/images/medium.svg';
import { ReactComponent as Facebook } from '../../assets/images/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/images/instagram.svg';
import { ReactComponent as YouTube } from '../../assets/images/you-tube.svg';
import { ReactComponent as Github } from '../../assets/images/github.svg';
import { ReactComponent as Btcv } from '../../assets/images/btcv.svg';
import { ReactComponent as Twitter } from '../../assets/images/twitter.svg';
import { SOCIAL_MEDIA_LINKS } from '../shared/consts';

const Logo = styled.div<{ color?: string }>`
  color: ${({ color, theme }) => color ?? theme.colors.black};
`;

interface SocialMediaItem {
  name: string;
  svg: React.ReactNode;
  url: string;
}

export const SOCIAL_MEDIA_LIST: SocialMediaItem[] = [
  {
    name: SOCIAL_MEDIA_LINKS.twitter.name,
    svg: <Twitter />,
    url: SOCIAL_MEDIA_LINKS.twitter.url,
  },
  {
    name: SOCIAL_MEDIA_LINKS.telegram.name,
    svg: <Telegram />,
    url: SOCIAL_MEDIA_LINKS.telegram.url,
  },
  {
    name: SOCIAL_MEDIA_LINKS.medium.name,
    svg: <Medium />,
    url: SOCIAL_MEDIA_LINKS.medium.url,
  },
  {
    name: SOCIAL_MEDIA_LINKS.facebook.name,
    svg: <Facebook />,
    url: SOCIAL_MEDIA_LINKS.facebook.url,
  },
  {
    name: SOCIAL_MEDIA_LINKS.instagram.name,
    svg: <Instagram />,
    url: SOCIAL_MEDIA_LINKS.instagram.url,
  },
  {
    name: SOCIAL_MEDIA_LINKS.youTube.name,
    svg: <YouTube />,
    url: SOCIAL_MEDIA_LINKS.youTube.url,
  },
  {
    name: SOCIAL_MEDIA_LINKS.github.name,
    svg: <Github />,
    url: SOCIAL_MEDIA_LINKS.github.url,
  },
  {
    name: SOCIAL_MEDIA_LINKS.btcv.name,
    svg: <Btcv />,
    url: SOCIAL_MEDIA_LINKS.btcv.url,
  },
];

interface SocialMediaProps {
  data?: SocialMediaItem[];
  iconColor?: string;
}

const SocialMediaList = ({
  data = SOCIAL_MEDIA_LIST,
  iconColor,
}: SocialMediaProps): JSX.Element => {
  return (
    <>
      {data.map(({ name, svg, url }) => (
        <a key={name} href={url} target="_blank" rel="noopener noreferrer">
          <Logo color={iconColor}>{svg}</Logo>
        </a>
      ))}
    </>
  );
};

export default SocialMediaList;

SocialMediaList.defaultProps = {
  data: SOCIAL_MEDIA_LIST,
  iconColor: null,
};
