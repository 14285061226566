import React, { useRef } from 'react';
import {
  Wrapper,
  Backdrop,
  Sidebar,
  MobileLogo,
  CloseButton,
  CloseMobile,
  CloseDesktop,
  SocialMediaWrapper,
  InnerWrapper,
} from './styled';
import SocialMediaList from '../SocialMediaList';
import useOuterClick from '../../hooks/useOuterClick';
import theme from '../../styles/theme';
import MenuItems from './MenuItems';

interface MenuProps {
  isMenuOpen: boolean;
  handleClose: () => void;
}

const Menu = ({ isMenuOpen, handleClose }: MenuProps): JSX.Element => {
  const menuRef = useRef(null);

  useOuterClick(menuRef, handleClose);

  return (
    <Wrapper isMenuOpen={isMenuOpen}>
      <Backdrop />
      <Sidebar ref={menuRef}>
        <MobileLogo />
        <CloseButton onClick={handleClose}>
          <CloseMobile />
          <CloseDesktop />
        </CloseButton>
        <SocialMediaWrapper>
          <SocialMediaList iconColor={theme.colors.secondary} />
        </SocialMediaWrapper>
        <InnerWrapper>
          <MenuItems handleClose={handleClose} />
        </InnerWrapper>
      </Sidebar>
    </Wrapper>
  );
};

export default Menu;
