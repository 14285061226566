import styled from 'styled-components';
import { ReactComponent as Button } from '../../assets/images/back-button.svg';

export const Overlay = styled.div<{ modalOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: ${({ modalOpen }) => (modalOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 2;
  ${({ theme }) => `border-radius: ${theme.borderRadius}`};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 653px;
  margin-top: 17px;
  margin-bottom: 250px;
  position: relative;

  ${({ theme }) => `background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius}`};

  @media ${({ theme }) => `${theme.device.lg}`} {
    max-width: 650px;
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    max-width: 315px;
    height: auto;
    margin-bottom: 150px;
    margin-top: 17px;
  }
`;

export const BackButton = styled(Button)`
  margin-right: 20px;
  cursor: pointer;
`;

export const Headline = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: bold;
  margin-bottom: 30px;

  ${({ theme }) => `font-size: ${theme.fontSize.mobileTitleBig}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    max-width: 180px;
    margin-bottom: 49px;
  }
`;

export const Content = styled.div`
  padding: 31px 40px 0px 40px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding: 20px 20px 0px 20px;
  }
`;

export const ConversionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const FeeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  > * {
    &:first-child {
      margin-right: 70px;
    }
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    flex-direction: column;
    text-align: center;

    > * {
      &:first-child {
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
  }
`;

export const ConfirmationRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media ${({ theme }) => `${theme.device.xs}`} {
    flex-direction: column;
  }
`;

export const WalletRow = styled.div<{ direction: string }>`
  display: flex;
  margin-bottom: 20px;

  ${({ direction }) =>
    direction === 'rtl' ? 'flex-direction: row-reverse' : ''};

  @media ${({ theme }) => `${theme.device.xs}`} {
    word-break: break-word;
    text-align: center;
  }
`;

export const EmailRow = styled.div<{ direction: string }>`
  display: flex;
  margin-bottom: 20px;

  ${({ direction }) =>
    direction === 'rtl' ? 'flex-direction: row-reverse' : ''};

  @media ${({ theme }) => `${theme.device.xs}`} {
    word-break: break-word;
    text-align: center;
    flex-direction: column;
  }
`;

export const ConfirmationText = styled.div`
  ${({ theme }) => `font-size: ${theme.fontSize.regular}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin-bottom: 20px;
  }
`;
