/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import i18n from 'i18next';
import { RTL_LANGUAGES } from '../components/shared/consts';

const useLanguageDir = (): string => {
  const currentLanguage: string =
    i18n.language || window.localStorage.i18nextLng || 'en';
  const [languageDirection, setLanguageDirection] = useState('ltr');

  useEffect(() => {
    if (RTL_LANGUAGES.includes(currentLanguage)) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('ltr');
    }
  }, [currentLanguage]);

  return languageDirection;
};

export default useLanguageDir;
