import React, { useState, useRef } from 'react';
import {
  RouteComponentProps,
  useLocation,
  useHistory,
  useParams,
} from 'react-router';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import _ from 'lodash';
import {
  Wrapper,
  Headline,
  Content,
  FeeRow,
  ConversionRow,
  ConfirmationRow,
  WalletRow,
  EmailRow,
  BackButton,
  ConfirmationText,
  Overlay,
} from './styled';
import Button from '../shared/button';
import { Line, CenteredContent } from '../shared/styled/styled';
import SummaryField from '../shared/SummaryField';
import VerificationCode from '../VerificationCode';
import { ButtonSize, Routes } from '../shared/consts/index';
import { ExtendedFormValues } from '../BTCVConverter/interfaces';
import Layout from '../shared/Layout';
import useSwapValidate from '../../hooks/useSwapValidate';
import useSwapInit from '../../hooks/useSwapInit';
import { SwapValidateResponse, ParamTypes } from './interfaces';
import { SwapErrorResponse } from '../shared/interfaces/response.interface';
import { Chain } from '../BTCVConverter/consts';
import { ModalFuncProps, ModalType } from '../shared/modal/interfaces';
import Modal from '../shared/modal';
import useLanguageDir from '../../hooks/useLanguageDir';

const getSwapDirectionName = (direction: Chain) => {
  switch (direction) {
    case Chain.BTCV:
      return 'BTCV';
    default:
      return 'wBTCV';
  }
};

const TransactionSummary: React.FC<RouteComponentProps> = () => {
  const errorModal = useRef<ModalFuncProps>(null);
  const history = useHistory();
  const { token } = useParams<ParamTypes>();
  const [pinCode, setPinCode] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const { state } = useLocation<ExtendedFormValues>();
  const { t } = useTranslation();
  const dir = useLanguageDir();
  const currentLanguage: string =
    i18n.language || window.localStorage.i18nextLng || 'en';

  const { refetch: swapValidate, isLoading } = useSwapValidate(
    token,
    pinCode,
    (res: SwapValidateResponse) => {
      if (res.result === 'success') {
        history.push({
          pathname: `${Routes.CompleteTransaction}/${res.swap_id}`,
          state: res,
        });
      }
    },
    (error: SwapErrorResponse) => {
      setModalOpen(true);
      errorModal.current?.openErrorMessage(error.msg);
    }
  );

  const { refetch: swapInit } = useSwapInit(
    state?.email,
    currentLanguage,
    state?.swapDirection.from,
    state?.swapDirection.to,
    state?.walletAddress,
    state?.send,
    (error: SwapErrorResponse) => {
      setModalOpen(true);
      errorModal.current?.openErrorMessage(error.msg);
    }
  );

  const handleConfirmConversion = () => {
    swapValidate();
  };

  const handleComplete = (code: string) => {
    setPinCode(code);
  };

  const handleBackHome = () => {
    history.push({
      pathname: `${Routes.Converter}`,
      state,
    });
  };

  const handleCodeRefresh = () => {
    swapInit().then((res: any) => {
      if (res.status === 'success') {
        history.push({
          pathname: `${Routes.Summary}/${res.data.session_token}`,
          state,
        });
      }
    });
    setPinCode('');
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  if (_.isEmpty(state)) {
    history.push({
      pathname: Routes.Converter,
    });
    return null;
  }
  return (
    <>
      <Layout>
        <Wrapper>
          <Overlay modalOpen={modalOpen} />
          <Content>
            <Headline>
              <BackButton data-testid="button-back" onClick={handleBackHome} />{' '}
              {t('headline.transactionSummary')}
            </Headline>
            <ConversionRow>
              <SummaryField
                id="sendAmount"
                label={t('input.send')}
                value={`${parseFloat(state.send)} ${getSwapDirectionName(
                  state.swapDirection.from
                )}`}
                dir={dir}
              />
              <SummaryField
                id="getAmount"
                label={t('input.get')}
                value={`${state.get} ${getSwapDirectionName(
                  state.swapDirection.to
                )}`}
                dir={dir}
              />
            </ConversionRow>
            <Line />
            <FeeRow>
              <SummaryField
                id="exchangeFee"
                label={t('exchangeFee')}
                value={`${state.exchangeFee} BTCV`}
                description={t('exchangeFeeDescription')}
                dir={dir}
              />
              <SummaryField
                id="networkFee"
                label={t('networkFee')}
                value={`${state.networkFee} BTCV`}
                description={t('networkFeeDescription')}
                dir={dir}
              />
            </FeeRow>
            <WalletRow direction={dir}>
              <SummaryField
                id="walletAddress"
                label={t('input.walletAddress.label')}
                value={state.walletAddress}
                dir={dir}
              />
            </WalletRow>
            <EmailRow direction={dir}>
              <SummaryField
                id="emailAddress"
                label={t('email')}
                value={state.email}
                dir={dir}
              />
            </EmailRow>
            <Line />
            <ConfirmationRow>
              <ConfirmationText>{t('confirmationCode')}</ConfirmationText>
              <VerificationCode
                onComplete={handleComplete}
                onCodeRefresh={handleCodeRefresh}
              />
            </ConfirmationRow>
            <CenteredContent>
              <Button
                data-testid="button-confirm"
                size={ButtonSize.Large}
                onClick={handleConfirmConversion}
                disabled={pinCode.length < 6}
                isLoading={isLoading}
              >
                {t('button.confirmConversion')}
              </Button>
            </CenteredContent>
          </Content>
        </Wrapper>
      </Layout>
      <Modal
        ref={errorModal}
        type={ModalType.ERROR}
        onClose={handleModalClose}
      />
    </>
  );
};

export default TransactionSummary;
