import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';
import querystring from 'querystring';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import useLanguageDir from '../../hooks/useLanguageDir';
import { ButtonSize, FeedbackStatus } from '../shared/consts/index';
import FeedbackSuccessModal from './FeedbackSuccess';
import { Config } from '../../Config';
import {
  FeedbackButton,
  FeedbackModal,
  InnerWrapper,
  FeedbackModalLeft,
  LeaveFeedbackTitle,
  FeedbackOptions,
  FeedbackOption,
  FeedbackOptionRating,
  FeedbackDescription,
  FeedbackModalRight,
  FeedbackInput,
  FeedbackModalCloseButton,
  SendButton,
  SubmitErrorText,
  FeedbackErrorText,
  ReCaptchaText,
} from './styled';

const Feedback = () => {
  const { t } = useTranslation();
  const dir = useLanguageDir();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [modalOpen, setModalOpen] = useState(false);
  const [status, setStatus] = useState<FeedbackStatus>(FeedbackStatus.INITIAL);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [feedbackNumber, setFeedbackNumber] = useState<null | number>(null);
  const [feedbackNumberError, setFeedbackNumberError] = useState<null | string>(
    null
  );

  const resetForm = () => {
    setName('');
    setEmail('');
    setMessage('');
    setFeedbackNumber(null);
  };

  const displayFeedbackModal = () => setModalOpen(true);
  const goBackToFeedbackModal = () => {
    setModalOpen(false);
    setStatus(FeedbackStatus.INITIAL);
    setFeedbackNumber(null);
  };

  const handleFeedbackChange = (number: number) => {
    setFeedbackNumberError(null);
    setStatus(FeedbackStatus.INITIAL);
    setFeedbackNumber(number);
  };

  const isLoading = status === FeedbackStatus.LOADING;
  const feedbackEmailAddress = 'feedback@bitcoinvault.global';

  const handleFeedbackSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (!feedbackNumber) {
      setFeedbackNumberError(t('requiredField'));
      return;
    }

    setStatus(FeedbackStatus.LOADING);

    try {
      const token = await executeRecaptcha?.('contact_form');

      const { data } = await axios.post(
        Config.feedbackFormUrl,
        querystring.stringify({
          'your-name': name,
          'your-email': email,
          'your-subject': 'Message from feedback form.',
          'your-message': `Feedback number: ${feedbackNumber}. ${message}`,
          _wpcf7_recaptcha_response: token,
        })
      );

      if (data?.status === 'mail_sent') {
        setStatus(FeedbackStatus.SUCCESS);
        setModalOpen(false);
        resetForm();
      } else {
        setStatus(FeedbackStatus.ERROR);
      }
    } catch (e) {
      setStatus(FeedbackStatus.ERROR);
    }
  };

  return (
    <>
      <FeedbackButton size={ButtonSize.Xlarge} onClick={displayFeedbackModal}>
        <span>{t('leaveAFeedbackTitle')}</span>
      </FeedbackButton>
      {modalOpen && (
        <FeedbackModal
          aria-label="Leave a feedback dialog"
          onDismiss={goBackToFeedbackModal}
          dir={dir}
        >
          <FeedbackModalLeft>
            <InnerWrapper>
              <LeaveFeedbackTitle direction={dir}>
                {t('leaveAFeedbackTitle')}
              </LeaveFeedbackTitle>
              <FeedbackOptions>
                <FeedbackOption
                  chosenFeedBackIcon={feedbackNumber === 1}
                  onClick={() => handleFeedbackChange(1)}
                >
                  <span role="img" aria-label="Angry Face emoji">
                    😠
                  </span>
                  <FeedbackOptionRating>1</FeedbackOptionRating>
                </FeedbackOption>
                <FeedbackOption
                  chosenFeedBackIcon={feedbackNumber === 2}
                  onClick={() => handleFeedbackChange(2)}
                >
                  <span role="img" aria-label="Frowning Face emoji">
                    🙁
                  </span>
                  <FeedbackOptionRating>2</FeedbackOptionRating>
                </FeedbackOption>
                <FeedbackOption
                  chosenFeedBackIcon={feedbackNumber === 3}
                  onClick={() => handleFeedbackChange(3)}
                >
                  <span role="img" aria-label="Neutral Face emoji">
                    😐
                  </span>
                  <FeedbackOptionRating>3</FeedbackOptionRating>
                </FeedbackOption>
                <FeedbackOption
                  chosenFeedBackIcon={feedbackNumber === 4}
                  onClick={() => handleFeedbackChange(4)}
                >
                  <span role="img" aria-label="Slightly Smiling Face emoji">
                    🙂
                  </span>
                  <FeedbackOptionRating>4</FeedbackOptionRating>
                </FeedbackOption>
                <FeedbackOption
                  chosenFeedBackIcon={feedbackNumber === 5}
                  onClick={() => handleFeedbackChange(5)}
                >
                  <span
                    role="img"
                    aria-label="Smiling Face with Heart-Shaped Eyes emoji"
                  >
                    😍
                  </span>
                  <FeedbackOptionRating>5</FeedbackOptionRating>
                </FeedbackOption>
              </FeedbackOptions>
              {feedbackNumberError && (
                <FeedbackErrorText>{feedbackNumberError}</FeedbackErrorText>
              )}
              <FeedbackDescription direction={dir}>
                {t('leaveAFeedbackText')}{' '}
                <a href={`mailto:${feedbackEmailAddress}`}>
                  {feedbackEmailAddress}
                </a>
              </FeedbackDescription>
            </InnerWrapper>
          </FeedbackModalLeft>
          <FeedbackModalRight onSubmit={handleFeedbackSubmit}>
            {status === FeedbackStatus.ERROR && (
              <SubmitErrorText>{t('somethingWentWrong')}</SubmitErrorText>
            )}
            <FeedbackInput
              id="feedback-name"
              type="text"
              name="name"
              dir={dir}
              required
              label={t('name')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setName(event.target.value)
              }
              value={name}
            />
            <FeedbackInput
              id="feedback-email"
              type="text"
              name="email"
              dir={dir}
              required
              label={t('email')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(event.target.value)
              }
              value={email}
            />
            <FeedbackInput
              id="message"
              type="text"
              name="Message"
              dir={dir}
              required
              label={t('message')}
              onChange={(event: React.ChangeEvent<any>) =>
                setMessage(event.target.value)
              }
              value={message}
            />
            <ReCaptchaText direction={dir}>
              {t('recaptcha')}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Privacy Policy{' '}
              </a>{' '}
              &
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Terms of Service
              </a>
            </ReCaptchaText>
            <SendButton disabled={isLoading} isLoading={isLoading}>
              {t('send')}
            </SendButton>
          </FeedbackModalRight>
          <FeedbackModalCloseButton onClick={goBackToFeedbackModal}>
            <CloseIcon />
          </FeedbackModalCloseButton>
        </FeedbackModal>
      )}
      {status === FeedbackStatus.SUCCESS && (
        <FeedbackSuccessModal onGoBackClick={goBackToFeedbackModal} />
      )}
    </>
  );
};

export default Feedback;
