export enum Routes {
  Converter = '/',
  Summary = '/summary',
  CompleteTransaction = '/transaction',
  Error = '/error',
}

export enum MessageType {
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum ButtonSize {
  Normal = 'normal',
  Large = 'large',
  Xlarge = 'xlarge',
}

export enum FeedbackStatus {
  INITIAL = 'initial',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}
export interface MenuItem {
  name: string;
  url: string;
}

export interface Menu extends MenuItem {
  links?: MenuItem[];
}

interface ColumnData {
  title: string;
  links: {
    name: string;
    link: string;
    img?: string;
  }[];
}

export const SOCIAL_MEDIA_LINKS = {
  twitter: {
    name: 'twitter',
    url: 'https://twitter.com/vaultbitcoin',
  },
  telegram: {
    name: 'telegram',
    url: 'https://t.me/Bitcoin_Vault',
  },
  medium: {
    name: 'medium',
    url: 'https://medium.com/bitcoin-vault-btcv',
  },
  facebook: {
    name: 'facebook',
    url: 'https://www.facebook.com/bitcoinvaultofficial',
  },
  instagram: {
    name: 'instagram',
    url: 'https://www.instagram.com/bitcoinvaultofficial/',
  },
  youTube: {
    name: 'youTube',
    url: 'https://www.youtube.com/c/BitcoinVault',
  },
  github: {
    name: 'github',
    url: 'https://github.com/bitcoinvault',
  },
  btcv: {
    name: 'btcv',
    url: 'https://explorer.bitcoinvault.global/',
  },
};

export const NAVIGATION_MENU: Menu[] = [
  {
    name: 'home',
    url: '/',
  },
  {
    name: 'development',
    url: '/btcv-explained',
    links: [
      {
        name: 'btcvExplained',
        url: 'https://bitcoinvault.global/btcv-explained',
      },
      {
        name: 'whitepaper',
        url: 'https://bitcoinvault.global/whitepaper',
      },
      {
        name: 'roadmap',
        url: 'https://bitcoinvault.global/roadmap',
      },
      {
        name: 'faq',
        url: 'https://bitcoinvault.global/faq',
      },
    ],
  },
  {
    name: 'features',
    url: 'https://bitcoinvault.global/features',
  },
  {
    name: 'ecosystem',
    url: 'https://bitcoinvault.global/ecosystem',
  },
  {
    name: 'education',
    url: 'https://bitcoinvault.global/tutorials',
  },
  {
    name: 'community',
    url: 'https://bitcoinvault.global/community',
  },
  {
    name: 'teamBehind',
    url: 'https://bitcoinvault.global/team-behind',
  },
  {
    name: 'contact',
    url: 'https://bitcoinvault.global/contact',
  },
];

export const RTL_LANGUAGES = ['ar'];

export const footerSections = {
  home: 'home',
  development: 'development',
  education: 'education',
  joinUsOn: 'joinUsOn',
  needHelp: 'needHelp',
};

export const FOOTER_DATA: ColumnData[] = [
  {
    title: footerSections.home,
    links: [
      {
        name: 'development',
        link: 'https://bitcoinvault.global/btcv-explained',
      },
      {
        name: 'features',
        link: 'https://bitcoinvault.global/features',
      },
      {
        name: 'ecosystem',
        link: 'https://bitcoinvault.global/ecosystem',
      },
      {
        name: 'education',
        link: 'https://bitcoinvault.global/tutorials',
      },
      {
        name: 'community',
        link: 'https://bitcoinvault.global/community',
      },
      {
        name: 'contact',
        link: 'https://bitcoinvault.global/contact',
      },
    ],
  },
  {
    title: footerSections.development,
    links: [
      {
        name: 'whitepaper',
        link: 'https://bitcoinvault.global/whitepaper',
      },
      {
        name: 'roadmap',
        link: 'https://bitcoinvault.global/roadmap',
      },
      {
        name: 'faq',
        link: 'https://bitcoinvault.global/faq',
      },
    ],
  },
  {
    title: footerSections.education,
    links: [
      {
        name: 'whatIsBitcoinVault',
        link: 'https://bitcoinvault.global/tutorials#what-is-bitcoin-vault',
      },
      {
        name: 'howToSetUpYourBitcoinVaultWallet',
        link: 'https://bitcoinvault.global/tutorials#how-to-set-up-your-bitcoin-vault-wallet',
      },
      {
        name: 'bitcoinVaultThreePrivateKeySecurityStructure',
        link: 'https://bitcoinvault.global/tutorials#bitcoin-vault-three-private-key-security-structure',
      },
      {
        name: 'howToConfigureLedgerWallet',
        link: 'https://bitcoinvault.global/tutorials#how-to-configure-ledger-wallet',
      },
      {
        name: 'howToMakeBitcoinVaultTransactionsUsingALedgerWallet',
        link: 'https://bitcoinvault.global/tutorials#how-to-make-bitcoin-vault-transactions-using-a-ledger-wallet',
      },
    ],
  },
];

export const EXPLORER_LINK = `https://explorer.bitcoinvault.global/`;
