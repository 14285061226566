import React from 'react';
import { ReactComponent as EN } from '../../../assets/images/flags/en.svg';
import { ReactComponent as ES } from '../../../assets/images/flags/es.svg';
import { ReactComponent as JA } from '../../../assets/images/flags/ja.svg';
import { ReactComponent as KO } from '../../../assets/images/flags/ko.svg';
import AR from '../../../assets/images/flags/ar.jpg';
import ID from '../../../assets/images/flags/id.jpg';
import { ReactComponent as PT } from '../../../assets/images/flags/pt.svg';
import { ReactComponent as VI } from '../../../assets/images/flags/vi.svg';
import { ReactComponent as ZH } from '../../../assets/images/flags/zh.svg';
import { ReactComponent as TR } from '../../../assets/images/flags/tr.svg';

export const languages = [
  {
    locale: 'en',
    name: 'English',
  },
  {
    locale: 'es',
    name: 'Español',
  },
  {
    locale: 'ar',
    name: 'العربية',
  },
  {
    locale: 'tr',
    name: 'Türkçe',
  },
  {
    locale: 'ja',
    name: '日本語',
  },
  {
    locale: 'id',
    name: 'Bahasa Indonesia',
  },
  {
    locale: 'ko',
    name: '한국어',
  },
  {
    locale: 'pt',
    name: 'Português',
  },
  {
    locale: 'vi',
    name: 'Tiếng Việt',
  },
  {
    locale: 'zh',
    name: '中文 (中国)',
  },
];

export const FLAGS: {
  [key: string]: React.ReactNode;
} = {
  en: <EN />,
  es: <ES />,
  ja: <JA />,
  ko: <KO />,
  pt: <PT />,
  vi: <VI />,
  zh: <ZH />,
  id: <img src={ID} alt="Indonesia flag" />,
  tr: <TR />,
  ar: <img src={AR} alt="Arab Union flag" />,
};

export enum LangAttribute {
  LOCALE = 'locale',
  NAME = 'name',
}
