import React, { useEffect } from 'react';
import * as _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as Tooltip } from '../../../assets/images/tolltip.svg';
import { ReactComponent as TooltipError } from '../../../assets/images/tolltip-error.svg';

import {
  StyledInput,
  InputContainer,
  StyledLabel,
  ExtraElementContainer,
  ErrorContainer,
} from './styled';
import { ExtraElement, InputProps } from './interfaces';

const Input: React.FC<InputProps> = ({
  id,
  testid,
  type,
  name,
  label,
  value,
  disabled,
  onChange,
  onBlur,
  extras,
  errors,
  dir = 'ltr',
  ...rest
}: InputProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const renderExtraElements = (
    extraEl: [ExtraElement, string][]
  ): React.ReactNode => {
    if (extraEl.length) {
      return extraEl.map((extraElement: [ExtraElement, string]) => {
        switch (extraElement[0]) {
          case ExtraElement.Text:
            return (
              <>
                <ExtraElementContainer
                  direction={dir}
                  data-test-id={`${extraElement[0]}-${extraElement[1]}`}
                >
                  {extraElement[1]}
                </ExtraElementContainer>
              </>
            );
          case ExtraElement.Tooltip:
            return (
              <>
                <ExtraElementContainer direction={dir}>
                  {_.isEmpty(errors) ? (
                    <>
                      <Tooltip data-tip={extraElement[1]} />
                      <ReactTooltip place="right" type="dark" effect="float" />
                    </>
                  ) : (
                    <>
                      <TooltipError data-tip={extraElement[1]} />
                      <ReactTooltip place="right" type="dark" effect="float" />
                    </>
                  )}
                </ExtraElementContainer>
              </>
            );
          default:
            return null;
        }
      });
    }
    return null;
  };

  return (
    <>
      <InputContainer>
        <StyledInput
          id={id}
          testid={testid}
          type={type}
          value={value}
          name={name}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={label}
          errors={errors}
          dir={dir}
          {...rest}
        />
        <StyledLabel
          active={!_.isEmpty(value.toString())}
          errors={errors}
          direction={dir}
        >
          {label}
        </StyledLabel>
        {extras && renderExtraElements(extras)}
        <ErrorContainer data-testid={`error-${id}`} direction={dir}>
          {errors}
        </ErrorContainer>
      </InputContainer>
    </>
  );
};

Input.defaultProps = {
  disabled: false,
  onBlur: () => {},
  extras: [],
};

export default Input;
