import styled from 'styled-components';

export const Overlay = styled.div<{ modalOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: ${({ modalOpen }) => (modalOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 2;
  ${({ theme }) => `border-radius: ${theme.borderRadius}`};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 755px;
  margin-top: 17px;
  margin-bottom: 250px;
  position: relative;

  ${({ theme }) => `background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius}`};

  @media ${({ theme }) => `${theme.device.lg}`} {
    max-width: 650px;
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    max-width: 315px;
    height: auto;
    margin-bottom: 150px;
    margin-top: 17px;
  }
`;

export const Content = styled.div`
  padding: 31px 40px 0px 40px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding: 0px 20px 0px 20px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin: 0px 20px;
  }
`;

export const Headline = styled.div<{ direction: string }>`
  font-weight: bold;
  margin-bottom: 20px;

  ${({ direction }) =>
    direction === 'rtl' ? 'text-align: right;' : 'text-align: left;'};
  ${({ theme }) => `font-size: ${theme.fontSize.mobileTitleBig}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin: 30px 0px 20px 0px;

    ${({ direction }) => (direction === 'rtl' ? '' : 'max-width: 180px')};

    ${({ direction }) =>
      direction === 'rtl' ? 'text-align: right;' : 'text-align: left;'};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FeeRow = styled.div<{ direction: string }>`
  display: flex;
  justify-content: space-between;
  margin: 40px 0px 20px 0px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    flex-direction: column;
    margin: 21px 0px 20px 0px;
    ${({ direction }) =>
      direction === 'rtl' ? 'text-align: right;' : 'text-align: left;'};
  }
`;

export const SwitcherRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 17px 0px;
`;

export const Line = styled.hr`
  height: 1px;
  border: none;
  margin-bottom: 40px;

  ${({ theme }) => `background-color: ${theme.colors.secondary}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin-bottom: 20px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;

  > * {
    &:first-child {
      margin-bottom: 30px;
    }
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    > * {
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
`;

export const ConversionRatio = styled.span`
  ${({ theme }) => `color: ${theme.colors.secondary}`}
`;

export const PDFLink = styled.a`
  color: black;
  font-weight: bold;
`;

export const PrivacyPolicy = styled.span`
  display: block;
  @media ${({ theme }) => `${theme.device.lg}`} {
    padding-left: 33px;
  }
`;
