import { useQuery, setLogger } from 'react-query';
import axios from 'axios';
import { SwapValidateResponse } from '../components/TransactionSummary/interfaces';
import {
  SwapErrorResponse,
  SwapError,
} from '../components/shared/interfaces/response.interface';
import { Config } from '../Config';

setLogger({
  log: () => {},
  warn: () => {},
  error: () => {},
});

export const getSwapValidate = async (sessionToken: string, pin: string) => {
  const { data } = await axios.post(`${Config.ApiUrl}/swap/validate/`, {
    session_token: sessionToken,
    pin,
  });

  return data;
};

export default function useSwapValidate(
  sessionToken: string,
  pin: string,
  success: (swapValidateData: SwapValidateResponse) => void,
  errorData: (data: SwapErrorResponse) => void
) {
  return useQuery(
    ['swapvalidate', sessionToken, pin],
    () => getSwapValidate(sessionToken, pin),
    {
      onSuccess: (data: SwapValidateResponse) => {
        success(data);
      },
      onError: (error: SwapError) => {
        errorData(error.response.data);
      },
      refetchOnWindowFocus: false,
      enabled: false,
      retry: 0,
    }
  );
}
