import styled, { css } from 'styled-components';

export const fullWidthStyles = css`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;

export const TopbarStatsContainer = styled.aside`
  ${fullWidthStyles};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.black};
  height: ${({ theme }) => theme.dimensions.topbarHeight}px;
  flex-shrink: 0;
  position: relative;
  z-index: 3;
  padding: 0 30px;
`;

export const InnerWrapper = styled.div`
  max-width: ${({ theme }) => theme.dimensions.innerWrapper}px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;

export const Statistic = styled.div<{ isVisible?: boolean }>`
  display: flex;
  align-items: center;
  opacity: 1;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin-right: 4px;

  @media ${({ theme }) => theme.device.xs} {
    font-size: ${({ theme }) => theme.fontSize.label};
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.regular};

  @media ${({ theme }) => theme.device.xs} {
    font-size: ${({ theme }) => theme.fontSize.label};
  }
`;

export const TextWrapper = styled.div`
  display: flex;

  a {
    margin-left: 20px;
  }
`;
