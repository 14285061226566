const size = {
  xs: '655px',
  md: '990px',
  lg: '1200px',
};

const deviceSize = {
  mobile: 655,
  desktop: 1200,
};

const device = {
  xs: `(max-width: ${size.xs})`,
  md: `(max-width: ${size.md})`,
  lg: `(min-width: ${size.xs})`,
};

const dimensions = {
  outerWrapper: 1920,
  wideSection: 1365,
  topSection: 1260,
  innerWrapper: 1080,
  topbarHeight: 40,
};

const ui = {
  transition: (prop, multiplier = 1) => `${prop} ${multiplier * 200}ms ease-in`,
  opacity: (value) => `${value || 0.8}`,
};

const theme = {
  colors: {
    black: '#000000',
    darkGray: '#C5C5C5',
    lightGrey: '#919191',
    textRed: '#910734',
    white: '#ffffff',
    red: '#840533',
    secondary: '#d6b56d',
    buttonDisabledYellow: '#eadbb6',
    lightGray: '#f6f6f6',
    primaryMedium: '#5e0126',
    quaternary: '#860a39',
    yellow: '#C89C65',
  },
  fontSize: {
    label: '13px',
    regular: '15px',
    title: '18px',
    mobileTitleBig: '24px',
    desktopTitle: '28px',
    menuTitle: '32px',
    desktopBig: '48px',
  },
  fontWeight: {
    light: 300,
    regular: 400,
    bold: 700,
  },
  borderRadius: '8px',
  size,
  device,
  deviceSize,
  dimensions,
  ui,
};

export default theme;
