import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  MenuItem,
  MenuItemWithList,
  SectionTitle,
  ToggleList,
  MenuLink,
  List,
  Tick,
  StyledLink,
} from './styled';
import { NAVIGATION_MENU, EXPLORER_LINK } from '../shared/consts';
import MenuLinks from './MenuLinks';

interface MenuItemsProps {
  handleClose: () => void;
}

const MenuItems = ({ handleClose }: MenuItemsProps): JSX.Element => {
  const [openList, setOpenList] = useState('');
  const location = useLocation();
  const { t } = useTranslation();

  const handleToggleList = (name: string) => {
    if (openList === name) {
      setOpenList('');
    } else {
      setOpenList(name);
    }
  };

  const handleMenuElementClick = (url: string) => {
    if (location.pathname === url) {
      return handleClose();
    }
    return null;
  };

  return (
    <>
      {NAVIGATION_MENU.map(({ name, url, links }, index) => {
        const isListOpen = name === openList;

        return (
          <MenuItem key={name}>
            {links ? (
              <MenuItemWithList onClick={() => handleToggleList(name)}>
                <SectionTitle>{t(name)}</SectionTitle>
                <ToggleList>
                  <Tick open={isListOpen} />
                </ToggleList>
              </MenuItemWithList>
            ) : (
              <MenuLink href={url} onClick={() => handleMenuElementClick(url)}>
                <SectionTitle index={index}>{t(name)}</SectionTitle>
              </MenuLink>
            )}
            {links && isListOpen && (
              <List>
                <MenuLinks data={links} handleClose={handleClose} />
              </List>
            )}
          </MenuItem>
        );
      })}
      <MenuItem>
        <StyledLink
          href={EXPLORER_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('explorer')}
        </StyledLink>
      </MenuItem>
    </>
  );
};

export default MenuItems;
