import React from 'react';
import i18n from 'i18next';
import { languages, FLAGS } from './consts';
import { ListItem, StyledLink, Text } from './styled';

const LanguagesList = (): JSX.Element => {
  const handleChangeLanguage = (locale: string) => {
    i18n.changeLanguage(locale);
  };

  const getCurrentLng: string =
    i18n.language || window.localStorage.i18nextLng || '';

  return (
    <>
      {languages.map(({ locale, name }) => {
        return (
          locale !== getCurrentLng.slice(0, 2) && (
            <ListItem key={locale}>
              <StyledLink onClick={() => handleChangeLanguage(locale)}>
                {FLAGS[locale]}
                <Text>{name}</Text>
              </StyledLink>
            </ListItem>
          )
        );
      })}
    </>
  );
};

export default LanguagesList;
