import styled from 'styled-components';
import { ReactComponent as RefreshButton } from '../../assets/images/refresh-code.svg';

export const CodeInput = styled.div`
  width: 289px;
  padding: 19px 75px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 24px;
  margin-right: 10px;

  ${({ theme }) => `background-color: ${theme.colors.lightGray};
  border-radius: ${theme.borderRadius};
  font-size: ${theme.fontSize.title};`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding: 17px 30px;
    width: 201px;
  }
`;

export const CodeRefresh = styled.div`
  height: 64px;
  width: 81px;
  transform: scaleX(-1);
  position: relative;

  ${({ theme }) => `background-color: ${theme.colors.lightGray};
  border-radius: ${theme.borderRadius};`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    width: 64px;
  }
`;

export const Refresh = styled(RefreshButton)`
  position: absolute;
  top: 24px;
  left: 30px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    left: 22px;
  }
`;

export const Input = styled.input`
  width: 10px;
  font-weight: bold;
  line-height: 24px;
  border: none;

  ${({ theme }) => `background-color: ${theme.colors.lightGray};
  font-size: ${theme.fontSize.title};
  border-bottom: 1px solid ${theme.colors.darkGray}`};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const InputElements = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const VerificationCodeWrapper = styled.div`
  display: flex;

  @media ${({ theme }) => `${theme.device.xs}`} {
    flex-direction: row;
    margin-bottom: 20px;
  }
`;

export const Counter = styled.div`
  position: absolute;
  top: 22px;
  left: 29px;
  transform: scaleX(-1);

  ${({ theme }) => `color: ${theme.colors.lightGrey};
  font-size: ${theme.fontSize.regular}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    left: 21px;
  }
`;
