import React, { useState } from 'react';
import {
  CodeRefresh,
  Refresh,
  VerificationCodeWrapper,
  Counter,
} from './styled';
import CodeInput from './CodeInput';
import useInterval from './hooks';

interface VerificationCodeProps {
  onComplete: (code: string) => void;
  onCodeRefresh: () => void;
}

const VerificationCode: React.FC<VerificationCodeProps> = ({
  onComplete,
  onCodeRefresh,
}: VerificationCodeProps) => {
  const REFRESH_CODE_TIMEOUT = 30;
  const [count, setCount] = useState<number>(REFRESH_CODE_TIMEOUT);
  const [isRefreshing, setRefresh] = useState(false);

  useInterval(
    () => {
      if (count > 0) {
        setCount(count - 1);
      }
      if (count === 0) {
        setCount(REFRESH_CODE_TIMEOUT);
        setRefresh(false);
      }
    },
    isRefreshing ? 1000 : null
  );

  const handleCodeRefresh = (): void => {
    if (!isRefreshing) {
      setRefresh(true);
      onCodeRefresh();
    }
  };

  return (
    <VerificationCodeWrapper>
      <CodeInput
        length={6}
        onComplete={onComplete}
        isRefreshing={isRefreshing}
      />
      <CodeRefresh onClick={handleCodeRefresh} data-testid="refresh-code">
        {isRefreshing ? <Counter>{count}s</Counter> : <Refresh />}
      </CodeRefresh>
    </VerificationCodeWrapper>
  );
};

export default VerificationCode;
