import { useQuery, setLogger } from 'react-query';
import axios from 'axios';
import {
  SwapErrorResponse,
  SwapError,
} from '../components/shared/interfaces/response.interface';
import { Config } from '../Config';

setLogger({
  log: () => {},
  warn: () => {},
  error: () => {},
});

export enum SwapStatus {
  PENDING = 'PENDING',
  ABANDONED = 'ABANDONED',
  DEPOSIT_INCOMPLETE = 'DEPOSIT_INCOMPLETE',
  DEPOSIT_DONE = 'DEPOSIT_DONE',
  RELEASED = 'RELEASED',
  FUNDS_SENT = 'FUNDS_SENT',
  SECURING_FUNDS = 'SECURING_FUNDS',
  RETURNING_FUNDS = 'RETURNING_FUNDS',
  FUNDS_RETURNED = 'FUNDS_RETURNED',
  FINISHED = 'FINISHED',
}

export interface SwapIdResponse {
  swap_id: string;
  currency_in: string;
  currency_out: string;
  exchange_fee: number;
  network_fee: number;
  deposit_address: string;
  destination_address: string;
  chain_out_tx_id: string;
  timestamp: Date;
  timeout_timestamp: Date;
  swap_status: SwapStatus;
  amount_requested: string;
  amount_confirmed: string;
  amount_received: string;
  result: string;
  error_code: number;
  msg: string;
}

export const getSwapValidate = async (id: string): Promise<SwapIdResponse> => {
  const { data } = await axios.get(`${Config.ApiUrl}/swap/${id}/`);

  return data;
};

export default function useSwapById(
  id: string,
  interval: false | number,
  enableRefetching: boolean,
  success: (data: SwapIdResponse) => void,
  errorData: (data: SwapErrorResponse) => void
) {
  return useQuery(['swapbyid', id], () => getSwapValidate(id), {
    refetchOnWindowFocus: false,
    enabled: enableRefetching,
    refetchInterval: interval,
    onSuccess: (data: SwapIdResponse) => {
      success(data);
    },
    onError: (error: SwapError) => {
      errorData(error.response.data);
    },
    retry: 1,
  });
}
