import React from 'react';
import { ProgressWrapper, ProgressPercentage } from './styled';

interface ProgressBarProps {
  value: number;
  max: number;
  width: number;
}

const ProgressBar = ({ value, max, width }: ProgressBarProps): JSX.Element => {
  return (
    <ProgressWrapper width={width}>
      <ProgressPercentage>{value}%</ProgressPercentage>
      <progress value={value} max={max} />
    </ProgressWrapper>
  );
};

export default ProgressBar;
