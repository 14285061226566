import React, { useRef, useState } from 'react';
import i18n from 'i18next';
import useOuterClick from '../../../hooks/useOuterClick';
import { ReactComponent as Arrow } from '../../../assets/images/arrow-select.svg';
import {
  Wrapper,
  CurrentLanguage,
  ArrowWrapper,
  LanguageListWrapper,
  SelectedLanguageLocale,
  SelectedLanguageName,
} from './styled';
import { languages, FLAGS, LangAttribute } from './consts';
import LanguagesList from './LanguagesList';

interface LanguageSelectProps {
  listOnBottom?: boolean;
  topBar?: boolean;
}

const LanguageSelect = ({
  listOnBottom = false,
  topBar = false,
}: LanguageSelectProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const getCurrentLng: string =
    i18n.language || window.localStorage.i18nextLng || '';

  useOuterClick(ref, () => setIsOpen(false));

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };

  const selectedLanguageFlag = FLAGS[getCurrentLng.slice(0, 2)];

  const getCurrentLang = (returnAttribute: LangAttribute) => {
    return languages.map(({ locale, name }) => {
      if (locale === getCurrentLng.slice(0, 2)) {
        return returnAttribute === LangAttribute.LOCALE ? locale : name;
      }
      return null;
    });
  };

  return (
    <Wrapper topBar={topBar} ref={ref}>
      <CurrentLanguage onClick={handleSelectClick}>
        {selectedLanguageFlag}
        <SelectedLanguageLocale>
          {getCurrentLang(LangAttribute.LOCALE)}
        </SelectedLanguageLocale>
        <SelectedLanguageName>
          {getCurrentLang(LangAttribute.NAME)}
        </SelectedLanguageName>
        <ArrowWrapper isOpen={isOpen} topBar={topBar}>
          <Arrow />
        </ArrowWrapper>
      </CurrentLanguage>
      <LanguageListWrapper
        isOpen={isOpen}
        listOnBottom={listOnBottom}
        topBar={topBar}
      >
        <LanguagesList />
      </LanguageListWrapper>
    </Wrapper>
  );
};

LanguageSelect.defaultProps = {
  listOnBottom: false,
  topBar: false,
};

export default LanguageSelect;
