import styled from 'styled-components';
import { ReactComponent as LogoSvg } from '../../assets/images/logo.svg';
import { ReactComponent as LogoWithTextSvg } from '../../assets/images/logo-with-text.svg';

export const NavigationContainer = styled.header<{ hideNav: boolean }>`
  padding: 20px 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 2;
  opacity: ${({ hideNav }) => (hideNav ? '0' : '1')};

  @media ${({ theme }) => theme.device.xs} {
    padding: 20px 30px 0;
  }
`;

export const StyledLink = styled.div`
  z-index: 3;
  cursor: pointer;
`;

export const DesktopLogo = styled(LogoSvg)`
  display: block;

  @media ${({ theme }) => theme.device.xs} {
    height: 50px;
    width: 46px;
    display: none;
  }
`;

export const MobileLogo = styled(LogoWithTextSvg)`
  display: none;

  @media ${({ theme }) => theme.device.xs} {
    display: block;
    width: 95px;
    height: 50px;
  }
`;

export const ToggleMenu = styled.button`
  background-color: transparent;
  outline: 0;
  cursor: pointer;
  z-index: 1;
  justify-self: end;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const SocialMediaWrapper = styled.aside`
  display: grid;
  grid-template-columns: repeat(8, 25px);
  grid-gap: 10px;

  @media ${({ theme }) => theme.device.xs} {
    display: none;
  }
`;
