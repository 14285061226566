/* eslint-disable react/no-array-index-key */
import React, { useState, useRef, useEffect } from 'react';
import { CodeInput, Input, InputElements } from './styled';

interface CodeInputProps {
  length: number;
  loading?: boolean;
  onComplete: (code: string) => void;
  isRefreshing: boolean;
}

const InputCode = ({
  length,
  loading,
  onComplete,
  isRefreshing,
}: CodeInputProps): JSX.Element => {
  const [code, setCode] = useState([...Array(length)].fill(''));
  const inputs = useRef<(HTMLInputElement | null)[]>([]);

  const handleOnComplete = (c: string[]): void => {
    const areAllNotEmpty = (pinCode: string[]): boolean => {
      return pinCode.every((number) => number !== '');
    };

    if (areAllNotEmpty(c)) {
      onComplete(c.join(''));
    }
  };

  useEffect(() => {
    if (isRefreshing) {
      setCode([...Array(length)].fill(''));
    }
  }, [isRefreshing, length]);

  const firstEmptyInput = (position: number) => {
    return !code[0].length && position === 0;
  };

  const clearLastDigit = (slot: number) => {
    const newCode = [...code];

    newCode[slot - 1] = '';
    setCode(newCode);
    inputs.current[slot - 1]?.focus();
  };

  const processInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    slot: number
  ) => {
    const insertedValue = e.target.value;

    const includesNonDigitSign = /[^0-9]/.test(insertedValue);

    if (includesNonDigitSign) {
      return;
    }

    const newCode = [...code];

    newCode[slot] = insertedValue;
    setCode(newCode);

    if (slot !== length - 1) {
      inputs.current[slot + 1]?.focus();
    }

    handleOnComplete(newCode);
  };

  const onKeyUp = (e: React.KeyboardEvent, slot: number) => {
    if (e.key === 'Backspace' && !code[slot] && slot !== 0) {
      clearLastDigit(slot);
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    const pastedCode = e.clipboardData.getData('Text');
    const pastedCodeSplited = pastedCode.split('');
    const MAX_CODE_LENGTH = length - 1;
    const includesNonDigitSign = /[^0-9]/.test(pastedCode);

    if (includesNonDigitSign) {
      return;
    }

    if (pastedCodeSplited.length < MAX_CODE_LENGTH) {
      const partialCode = [...Array(length)].fill('');

      partialCode.splice(0, pastedCodeSplited.length, ...pastedCodeSplited);
      setCode(partialCode);
    } else if (pastedCodeSplited.length > MAX_CODE_LENGTH) {
      const newCode = pastedCodeSplited.slice(0, length);

      setCode(newCode);

      handleOnComplete(newCode);
    } else {
      setCode(pastedCodeSplited);

      handleOnComplete(pastedCodeSplited);
    }
  };

  return (
    <CodeInput>
      <InputElements>
        {code.map((num, idx) => {
          return (
            <Input
              data-testid="code-input"
              key={idx}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={num}
              autoFocus={firstEmptyInput(idx)}
              readOnly={loading}
              onChange={(e) => processInput(e, idx)}
              onPaste={handlePaste}
              onKeyUp={(e) => onKeyUp(e, idx)}
              ref={(ref) => inputs.current.push(ref)}
            />
          );
        })}
      </InputElements>
    </CodeInput>
  );
};

InputCode.defaultProps = {
  loading: false,
};

export default InputCode;
