/* eslint-disable @typescript-eslint/ban-ts-comment */
import { alt_networks, Network, address } from 'bitcoinjs-lib';
import { TimeLeft } from '../components/CompleteTransaction/interfaces';
import { Config } from '../Config';

export const getDateDifference = (from: Date, to: Date): TimeLeft => {
  const unixFrom = Math.floor(new Date(from).getTime());
  const unixTo = Math.floor(new Date(to).getTime());

  if (unixFrom > unixTo) {
    return {
      hour: 0,
      minute: 0,
      second: 0,
    };
  }

  let delta = Math.abs(unixTo - unixFrom) / 1000;
  const result: TimeLeft = {
    hour: 0,
    minute: 0,
    second: 0,
  };
  const s = {
    hour: 3600,
    minute: 60,
    second: 1,
  };

  (Object.keys(s) as Array<keyof typeof s>).forEach((key) => {
    result[key as keyof TimeLeft] = Math.floor(delta / s[key]);
    delta -= result[key as keyof TimeLeft] * s[key];
  });

  return result;
};

// @ts-ignore
const networkName = alt_networks[Config.btcvNetwork] as Network;

export const checkBTCVaddress = (walletAddress: string) =>
  address.toOutputScript(walletAddress, networkName);

export const isBTCVAddressValid = (
  walletAddress: string | undefined
): boolean => {
  if (walletAddress) {
    try {
      checkBTCVaddress(walletAddress);

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  }
  return false;
};
