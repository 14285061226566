/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useRef, useEffect } from 'react';
import { useFormik, Field, FormikProvider, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Modal from '../shared/modal/Modal';
import { ModalType, ModalFuncProps } from '../shared/modal/interfaces';
import ConversionSection from './ConversionSection';
import Input from '../shared/Input';
import {
  Wrapper,
  FormWrapper,
  Headline,
  ButtonWrapper,
  Line,
  InputGroup,
  Content,
  Overlay,
  PrivacyPolicy,
  PDFLink,
} from './styled';
import Checkbox from '../shared/checkbox';
import Button from '../shared/button';
import { FormValues, ExtendedFormValues, SwapDirection } from './interfaces';
import { Routes } from '../shared/consts/index';
import Layout from '../shared/Layout';
import { btcvToBscDirection } from './consts';
import useSwapInit from '../../hooks/useSwapInit';
import yup from '../../utils/yup-extended';
import useLanguageDir from '../../hooks/useLanguageDir';

const extendFormData = (
  values: FormValues,
  networkFee: number,
  exchangeFee: number,
  swapDirection: SwapDirection
): ExtendedFormValues => {
  return {
    ...values,
    networkFee,
    exchangeFee,
    swapDirection,
  };
};

const initialValues: FormValues = {
  send: '',
  get: '',
  walletAddress: '',
  email: '',
  checkbox: false,
};

const BTCVConverter: React.FC = () => {
  const errorModal = useRef<ModalFuncProps>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [networkFee, setNetworkFee] = useState(0);
  const [exchangeFee, setExchangeFee] = useState(0);
  const [maxExchangeAmount, setMaxExchangeAmount] = useState(500);
  const [swapDirection, setSwapDirection] =
    useState<SwapDirection>(btcvToBscDirection);
  const history = useHistory();
  const { state } = useLocation<ExtendedFormValues>();
  const { t } = useTranslation();
  const dir = useLanguageDir();
  const currentLanguage: string =
    i18n.language || window.localStorage.i18nextLng || 'en';
  const minConversionValue = 0.02;

  const FormSchema = yup.object().shape({
    send: yup
      .number()
      .min(
        minConversionValue,
        t('errors.input.send.minValue', { minConversionValue })
      )
      .required(t('errors.input.required')),
    get: yup
      .number()
      .max(
        maxExchangeAmount,
        t('errors.input.get.maxValue', { maxExchangeAmount })
      ),
    walletAddress: yup
      .string()
      .isWalletAddressValid(
        t('errors.input.walletAddress.wrong'),
        swapDirection
      )
      .required(t('errors.input.walletAddress.required')),
    email: yup
      .string()
      .email(t('errors.input.email.wrong'))
      .required(t('errors.input.email.required')),
    checkbox: yup.boolean().oneOf([true], ''),
  });

  const formik: FormikProps<FormValues> = useFormik({
    initialValues,
    validationSchema: FormSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values: FormValues) => {
      setIsLoading(true);

      const extendedFormData = extendFormData(
        values,
        networkFee,
        exchangeFee,
        swapDirection
      );

      refetch().then((res: any) => {
        setIsLoading(false);

        if (res.status === 'success') {
          history.push({
            pathname: `${Routes.Summary}/${res.data.session_token}`,
            state: extendedFormData,
          });
        }
      });
    },
  });

  useEffect(() => {
    if (state !== undefined) {
      formik.setFieldValue('walletAddress', state.walletAddress);
      formik.setFieldValue('email', state.email);
    }
  }, []);

  const { refetch } = useSwapInit(
    formik.values.email,
    currentLanguage,
    swapDirection.from,
    swapDirection.to,
    formik.values.walletAddress,
    formik.values.send,
    (error: any) => {
      setModalOpen(true);
      errorModal.current?.openErrorMessage(error.msg);
    }
  );

  const handleSwapSuccess = (
    exchangeFeeValue: string,
    networkFeeValue: string,
    maxValue: number,
    direction: SwapDirection
  ) => {
    setNetworkFee(parseFloat(networkFeeValue));
    setExchangeFee(parseFloat(exchangeFeeValue));
    setMaxExchangeAmount(maxValue);
    setSwapDirection(direction);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Layout>
      <Wrapper>
        <Overlay modalOpen={modalOpen} />
        <Content>
          <Headline direction={dir}>{t('headline.converter')}</Headline>
          <FormWrapper>
            <form onSubmit={formik.handleSubmit}>
              <ConversionSection
                formik={formik}
                onSwapConditionsSuccess={handleSwapSuccess}
                state={state}
              />
              <Line />
              <InputGroup>
                <Input
                  id="walletAddress"
                  testid="walletAddress"
                  type="text"
                  name="walletAddress"
                  label={t('input.walletAddress.label')}
                  dir={dir}
                  value={formik.values.walletAddress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errors={
                    formik.touched.walletAddress && formik.errors.walletAddress
                      ? formik.errors.walletAddress
                      : undefined
                  }
                />
                <Input
                  id="email"
                  testid="email"
                  type="email"
                  name="email"
                  label={t('email')}
                  dir={dir}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errors={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : undefined
                  }
                />
              </InputGroup>
              <FormikProvider value={formik}>
                <Field
                  name="checkbox"
                  label={
                    <>
                      {t('input.agree')}{' '}
                      <PDFLink
                        data-test-id="terms-of-service"
                        href="../../docs/Terms_of_service.pdf"
                        target="_blank"
                      >
                        {t('tc')}
                      </PDFLink>{' '}
                      <PrivacyPolicy>
                        {t('privacySee')}{' '}
                        <PDFLink
                          data-test-id="privacy-policy"
                          href="../../docs/Privacy_policy.pdf"
                          target="_blank"
                        >
                          {t('privacy')}
                        </PDFLink>
                      </PrivacyPolicy>
                    </>
                  }
                  component={Checkbox}
                  checked={formik.values.checkbox}
                  formik={formik}
                />
              </FormikProvider>
              <ButtonWrapper>
                <Button
                  type="submit"
                  disabled={!formik.isValid}
                  isLoading={isLoading}
                >
                  {t('next')}
                </Button>
              </ButtonWrapper>
            </form>
          </FormWrapper>
          <Modal
            ref={errorModal}
            type={ModalType.ERROR}
            onClose={handleModalClose}
          />
        </Content>
      </Wrapper>
    </Layout>
  );
};

export default BTCVConverter;
