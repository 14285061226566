import React from 'react';
import { useTranslation } from 'react-i18next';
import SocialMediaList from '../SocialMediaList';
import {
  Wrapper,
  InnerWrapper,
  ColumnWrapper,
  ColumnTitle,
  StyledLink,
  SocialMediaWrapper,
  CookiesAndPrivacyWrapper,
  Text,
  LanguageWrapper,
} from './styled';
import FooterLinks from './FooterLinks';
import { footerSections } from '../shared/consts/index';
import LanguageSelect from '../shared/LanguageSelect';
import Feedback from '../Feedback';

const Footer = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <InnerWrapper>
        <FooterLinks />
        <ColumnWrapper gridArea={footerSections.joinUsOn}>
          <ColumnTitle>{t('joinUsOn')}</ColumnTitle>
          <SocialMediaWrapper>
            <SocialMediaList />
          </SocialMediaWrapper>
        </ColumnWrapper>
        <ColumnWrapper gridArea={footerSections.needHelp}>
          <ColumnTitle>{t('needHelp')}</ColumnTitle>
          <StyledLink href="https://bitcoinvault.global/contact/">
            {t('contact')}
          </StyledLink>
          <LanguageWrapper>
            <LanguageSelect />
          </LanguageWrapper>
          <CookiesAndPrivacyWrapper>
            <StyledLink href="https://bitcoinvault.global/privacy-policy/">
              {t('privacy')}
            </StyledLink>
          </CookiesAndPrivacyWrapper>
          <Text>
            © {new Date().getFullYear()} Bitcoin Vault.
            <br />
            {t('copyright')}
          </Text>
        </ColumnWrapper>
        <Feedback />
      </InnerWrapper>
    </Wrapper>
  );
};

export default Footer;
