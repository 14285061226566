import { Dialog } from '@reach/dialog';
import styled, { css } from 'styled-components';
import Input from '../shared/Input';
import Button from '../shared/button';

export const FeedbackButton = styled(Button)`
  position: absolute;
  top: -88px;
  right: 50%;
  transform: translate(50%);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  white-space: nowrap;

  @media ${({ theme }) => theme.device.lg} {
    right: 0;
    transform: unset;
  }

  @media ${({ theme }) => theme.device.xs} {
    top: -68px;
  }
`;

export const FeedbackModal = styled(Dialog)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 80%;
  border-radius: 8px;

  @media ${({ theme }) => theme.device.md} {
    width: 60%;
  }

  @media ${({ theme }) => theme.device.xs} {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 80%;
    border-radius: 8px;
  }

  @media ${({ theme }) => theme.device.lg} {
    flex-direction: row;
    width: 1012px;
    min-height: 542px;
    padding: 0;
  }
`;

export const InnerWrapper = styled.div`
  padding: 0px;

  @media ${({ theme }) => theme.device.xs} {
    padding: 40px 20px 20px;
  }
`;

export const FeedbackModalLeft = styled.div`
  flex-basis: 50%;
  background-color: ${({ theme }) => theme.colors.quaternary};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media ${({ theme }) => theme.device.lg} {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0;
  }
`;

export const LeaveFeedbackTitle = styled.h2<{ direction: string }>`
  color: ${({ theme }) => theme.colors.white};
  white-space: break-spaces;
  line-height: 1.12;

  @media ${({ theme }) => theme.device.lg} {
    color: ${({ theme }) => theme.colors.white};
    margin: ${({ direction }) =>
      direction === 'rtl' ? '100px 78px 0 0' : '100px 0 0 78px'};
    text-align: ${({ direction }) =>
      direction === 'rtl' ? 'right' : 'inherit'};
  }

  @media ${({ theme }) => theme.device.xs} {
    text-align: center;
  }
`;

export const FeedbackOptions = styled.ul`
  margin: 25px auto 10px;
  display: flex;
  justify-content: space-between;
  list-style: none;
  max-width: 300px;

  @media ${({ theme }) => theme.device.lg} {
    margin: 63px 78px 0 78px;
  }
`;

export const FeedbackOption = styled.li<{ chosenFeedBackIcon: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  transition: all 0.5s ease-out;

  span[role='img'] {
    font-size: 30px;

    @media ${({ theme }) => theme.device.lg} {
      font-size: 34px;
    }
  }

  ${({ chosenFeedBackIcon }) =>
    chosenFeedBackIcon &&
    css`
      transform: scale(1.3);
      color: ${({ theme }) => theme.colors.white};
    `}
`;

export const FeedbackOptionRating = styled.span`
  margin-top: 17px;
  font-size: 14px;
`;

export const FeedbackDescription = styled.p<{ direction: string }>`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.regular};
  line-height: 1.33;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin: 35px auto 0;
  max-width: 300px;

  @media ${({ theme }) => theme.device.lg} {
    margin: 43px 78px 0 78px;
    max-width: none;
    text-align: ${({ direction }) =>
      direction === 'rtl' ? 'right' : 'inherit'};
  }

  a {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const FeedbackModalRight = styled.form`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  margin: 20px auto 30px auto;
  padding: 0 20px;
  width: 100%;
  max-width: 300px;
  @media ${({ theme }) => theme.device.lg} {
    margin: 109px auto 0 auto;
  }
`;

export const FeedbackInput = styled(Input)`
  margin-bottom: 10px;
  width: 260px;
  min-height: 64px;
`;

export const FeedbackModalCloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;

  background: transparent;
  border: none;
  outline: none;

  :hover {
    cursor: pointer;
  }
`;

export const SendButton = styled(Button)<{ isLoading: boolean }>`
  margin: 30px auto 0;
  min-width: 200px;
  max-height: ${({ isLoading }) => isLoading && '47px'};
`;

export const SubmitErrorText = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 15px;

  @media ${({ theme }) => theme.device.lg} {
    margin-top: -30px;
  }
`;

export const FeedbackErrorText = styled.p`
  color: rgb(247, 9, 73);
  display: flex;
  max-width: 300px;
  margin: 0 auto -25px;
  @media ${({ theme }) => theme.device.lg} {
    text-align: left;
    margin: 10px 78px -25px 78px;
    max-width: none;
  }
`;

export const ReCaptchaText = styled.p<{ direction: string }>`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSize.label};
  text-align: ${({ direction }) => (direction === 'rtl' ? 'right' : 'inherit')};
  line-height: 1.3;

  a {
    color: ${({ theme }) => theme.colors.black};
  }
`;
