import styled from 'styled-components';

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 570px;
`;

export const FieldLabel = styled.div<{ direction: string }>`
  line-height: 24px;
  font-weight: 300;

  ${({ direction }) => (direction === 'rtl' ? 'text-align: right' : '')};

  ${({ theme }) => `font-size: ${theme.fontSize.regular};
  color: ${theme.colors.lightGrey}`};
`;

export const FieldValue = styled.div<{ direction: string }>`
  font-weight: bold;
  line-height: 24px;
  word-wrap: break-word;
  ${({ direction }) => (direction === 'rtl' ? 'text-align: right' : '')};

  ${({ theme }) => `font-size: ${theme.fontSize.title}`};
`;

export const FieldDescription = styled.div<{ direction: string }>`
  line-height: 17px;
  ${({ direction }) => (direction === 'rtl' ? 'text-align: right' : '')};

  ${({ theme }) => `font-size: ${theme.fontSize.regular};
  color: ${theme.colors.lightGrey}`};
`;
