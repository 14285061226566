import styled, { keyframes } from 'styled-components';
import { ButtonSize } from '../consts/index';

export const StyledButton = styled.button<{ size: ButtonSize }>`
  position: relative;
  cursor: pointer;
  padding: 14px 45px;
  padding: ${(props) =>
    props.size === ButtonSize.Xlarge ? '16px 55px' : '14px 45px'};
  letter-spacing: 1.7px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: ${(props) => (props.size === ButtonSize.Normal ? '161px' : '300px')};
  height: 48px;
  border-radius: 24px;
  border: none;
  font-weight: bold;
  margin: 40px 0px;
  letter-spacing: 0;

  ${({ theme }) => `color: ${theme.colors.red};
  background-color: ${theme.colors.secondary}; 
  font-size: ${theme.fontSize.regular}`};

  @media ${(props) => `${props.theme.device.xs}`} {
    margin: 20px 0px;
    padding: ${(props) =>
      props.size === ButtonSize.Normal ? '12px 28px' : '12px'};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
`;

const spinnerAnimation = keyframes`
 100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<{ active: boolean }>`
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-top-color: white;
  opacity: 0;
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  width: 16px;
  height: 16px;
  transition: opacity 250ms;
  animation: ${spinnerAnimation} 1s linear;
  animation-iteration-count: infinite;

  ${(props) => props.active && `opacity: 1; transition-delay: 250ms;`};
`;

export const Text = styled.p<{ active: boolean }>`
  transition: opacity 250ms;
  transition-delay: 250ms;

  ${(props) => props.active && `opacity: 0; transition-delay: 0s;`};
`;
