import React from 'react';
import {
  NavigationContainer,
  StyledLink,
  MobileLogo,
  DesktopLogo,
  SocialMediaWrapper,
  ToggleMenu,
} from './styled';
import { ReactComponent as Hamburger } from '../../assets/images/hamburger.svg';
import SocialMediaList from '../SocialMediaList';
import theme from '../../styles/theme';
import Menu from '../Menu';

interface NavigationProps {
  isMenuOpen: boolean;
  hideNav: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
}

const handleLogoClick = () => {
  window.location.href = 'https://bitcoinvault.global/';
};

const Navigation = ({
  isMenuOpen,
  setIsMenuOpen,
  hideNav,
}: NavigationProps): JSX.Element => {
  return (
    <NavigationContainer id="header" hideNav={hideNav}>
      <StyledLink onClick={handleLogoClick}>
        <MobileLogo />
        <DesktopLogo />
      </StyledLink>
      <SocialMediaWrapper>
        <SocialMediaList iconColor={theme.colors.secondary} />
      </SocialMediaWrapper>
      <ToggleMenu onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <Hamburger />
      </ToggleMenu>
      <Menu handleClose={() => setIsMenuOpen(false)} isMenuOpen={isMenuOpen} />
    </NavigationContainer>
  );
};

export default Navigation;
