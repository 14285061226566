import React, { ButtonHTMLAttributes } from 'react';
import { ButtonSize } from '../consts/index';

import { StyledButton, Spinner, Text } from './styled';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  size?: ButtonSize;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  size = ButtonSize.Normal,
  isLoading = false,
  ...props
}: ButtonProps) => (
  <StyledButton size={size} {...props}>
    <Spinner active={isLoading} />
    <Text active={isLoading}>{children}</Text>
  </StyledButton>
);

Button.defaultProps = {
  size: ButtonSize.Normal,
  isLoading: false,
};

export default Button;
